import React, { createRef, useEffect, lazy, Suspense } from "react";
import { createStore, StoreProvider, useStoreActions } from "easy-peasy";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { createLogger } from "redux-logger";
import { ToastContainer } from "react-toastify";
import HeaderNew from "./components/homepage/Header.jsx";
import "./scss/style.scss";
import model from "./store/index.js";

const EducationVideoPlayer = lazy(() =>
  import("./components/education/EducationVideoPlayer.jsx")
);
const NotFound404 = lazy(() => import("./components/custom/NotFound404.jsx"));
const PressDetails = lazy(() => import("./components/about/PressDetails.jsx"));
const EducationPage = lazy(() =>
  import("./components/homepage/Education/EducationPage.jsx")
);
const Login = lazy(() => import("./pages/Login.jsx"));
const PatientExercises = lazy(() => import("./pages/PatientExercises.jsx"));
const HomePage = lazy(() => import("./components/homepage/HomePage.jsx"));
const MuscleType = lazy(() => import("./components/MuscleType.jsx"));
const Question = lazy(() => import("./components/Question.jsx"));
const PricingPlan = lazy(() => import("./pricing/PricingPlan.jsx"));
const MasterServiceAgreement = lazy(() =>
  import("./pricing/MasterServiceAgreement.jsx")
);
const ProviderSubscription = lazy(() =>
  import("./pages/ProviderSubscription.jsx")
);
const Subscriptions = lazy(() => import("./components/Subscriptions.jsx"));
const SuccessPage = lazy(() => import("./pricing/SuccessPage.jsx"));
const BlogArticle = lazy(() => import("./components/BlogArticle.jsx"));
const PatDashboard = lazy(() =>
  import("./components/dashboard/PatDashboard.jsx")
);
const EducationPageModal = lazy(() =>
  import("./components/education/EducationPageModal.jsx")
);
const EducationArticle = lazy(() =>
  import("./components/EducationArticle.jsx")
);
const EvaluationPageModal = lazy(() =>
  import("./components/evaluation/EvaluationPageModal.jsx")
);
const Footer = lazy(() => import("./components/homepage/Footer.jsx"));
const FooterNew = lazy(() => import("./components/homepage/footernew.jsx"));
const AllProviders = lazy(() => import("./pages/Users/AllProviders.jsx"));
const AllUsers = lazy(() => import("./pages/Users/AllUsers.jsx"));
const AddBlog = lazy(() => import("./pages/AddBlog.jsx"));
const AddVideo = lazy(() => import("./pages/AddVideo.jsx"));
const AdvertisingPolicy = lazy(() => import("./pages/AdvertisingPolicy.jsx"));
const AskAnExpert = lazy(() => import("./pages/AskAnExpert.jsx"));
const AthleticTrainersProfile = lazy(() =>
  import("./pages/AtheleticTrainerForm/AthleticTrainersProfile.jsx")
);
const Post = lazy(() => import("./pages/Post.jsx"));
const ChatPage = lazy(() => import("./pages/ChatPage.jsx"));
const Cookiepolicy = lazy(() => import("./pages/Cookiepolicy.jsx"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword.jsx"));
const GymProfile = lazy(() => import("./pages/GymForm/GymProfile.jsx"));
const HospitalProfile = lazy(() =>
  import("./pages/HospitalForm/HospitalProfile.jsx")
);
const MessagePage = lazy(() => import("./pages/Messages.jsx"));
const PatientDashboard = lazy(() => import("./pages/PatientDashboard.jsx"));
const Patientwelcome = lazy(() => import("./pages/Patientwelcome.jsx"));
const PersonalTrainerProfile = lazy(() =>
  import("./pages/PersonalTrainer/PersonalProviderProfile.jsx")
);
const PhysicalTherapistProfile = lazy(() =>
  import("./pages/PhysicalTherapistForm/PhysicalTherapistProfile.jsx")
);
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy.jsx"));
const ProviderDashboard = lazy(() => import("./pages/ProviderDashboard.jsx"));
const ProviderDetails = lazy(() => import("./pages/ProviderDetails.jsx"));
const ProviderProfile = lazy(() =>
  import("./pages/BasicProviderForm/ProviderProfile.jsx")
);
const Providers = lazy(() => import("./pages/Providers.jsx"));
const ProvidersReferral = lazy(() => import("./pages/ProvidersReferral.jsx"));
const CreateReferral = lazy(() =>
  import("./pages/Referrals/CreateReferral.jsx")
);
const ReferralDetails = lazy(() =>
  import("./pages/Referrals/ReferralDetails.jsx")
);
const Referrals = lazy(() => import("./pages/Referrals/Referrals.jsx"));
const Register = lazy(() => import("./pages/Register.jsx"));
const RehabilitationCenterProfile = lazy(() =>
  import("./pages/RehabilitationCenterForm/RehabilitationCenterProfile.jsx")
);
const ResetPassword = lazy(() => import("./pages/ResetPassword.jsx"));
const Unsubscribe = lazy(() => import("./pages/Unsubscribe.jsx"));
const ReviewGuidelines = lazy(() => import("./pages/ReviewGuidelines.jsx"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions.jsx"));
const Video = lazy(() => import("./pages/Video.jsx"));
const WellnessProviderProfile = lazy(() =>
  import("./pages/WellnessProvider/WellnessProviderProfile.jsx")
);
const About = lazy(() => import("./components/about/About.jsx"));
const ClaimYourProfile = lazy(() => import("./pages/ClaimYourProfile.jsx"));
const PatientProfile = lazy(() => import("./pages/PatientProfile.jsx"));
const ClinicProfile = lazy(() =>
  import("./pages/ClinicForm/ClinicProfile.jsx")
);
const Appointment = lazy(() => import("./pages/Appointment.jsx"));
const Exercise = lazy(() => import("./pages/Exercise.jsx"));
const UnsubscribeMail = lazy(() => import("./pages/UnsubscribeMail.jsx"));
const PatientAppointmentHistory = lazy(() =>
  import("./pages/PatientAppointmentHistory.jsx")
);
const Exercises = lazy(() => import("./pages/Exercises.jsx"));
const BlogPost = lazy(() => import("./components/adminPosts/BlogPost.jsx"));
const ComingSoon = lazy(() => import("./components/common/ComingSoon.jsx"));
const BlogPostContentnew = lazy(() =>
  import("./components/adminPosts/BlogPostContentnew.jsx")
);
const ExerciseDetails = lazy(() => import("./pages/ExerciseDetails.jsx"));

const store = createStore(model, {
  middleware: [createLogger()],
});

const RequireAuth = ({ children }) => {
  let isAuthenticated = localStorage.getItem("mop-token");
  return isAuthenticated ? children : <Navigate to={"/login"} />;
};

const Data = () => {
  let location = useLocation();
  const mainContainer = createRef();
  const setMoreMenu = useStoreActions((actions) => actions.setMoreMenu);
  const setProviderMenu = useStoreActions((actions) => actions.setProviderMenu);
  const setPaginationData = useStoreActions(
    (actions) => actions.setPaginationData
  );
  const setFilterData = useStoreActions((actions) => actions.setFilterData);
  useEffect(() => {
    document.getElementById("main").scrollTo(0, 0);
    if (
      !(
        location.pathname.includes("/providers") ||
        location.pathname.includes("/provider-claim-your-profile")
      )
    ) {
      setFilterData({
        name: "",
        type: "",
        zipcode: "",
        ratings: "",
        mop: "",
        page: 0,
        search: "",
      });
    }

    if (
      !(
        location.pathname.includes("/exercises") ||
        location.pathname.includes("/exercise-details") ||
        location.pathname.includes("/exercise")
      )
    ) {
      setPaginationData({ page: 0 });
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className="d-flex w-100 h-100 flex-column">
      <HeaderNew />
      <Suspense fallback={<></>}>
        <FooterNew />
        <div
          id="main"
          ref={mainContainer}
          onClick={() => {
            setProviderMenu(false);
            setMoreMenu(false);
          }}
        >
          <ToastContainer />
          <EvaluationPageModal />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route
              path={"/patientwelcome/:name"}
              element={<Patientwelcome />}
            />
            <Route path="/unsubscribe-mail" element={<UnsubscribeMail />} />
            <Route path="/signup" element={<Register />} />
            <Route path="/pricing/neuro_surgeon/" element={<PricingPlan />}>
              <Route path={"/pricing/neuro_surgeon/:id"} />
            </Route>
            <Route path="/pricing/hospital/" element={<PricingPlan />}>
              <Route path={"/pricing/hospital/:id"} />
            </Route>
            <Route path="/pricing/clinic/" element={<PricingPlan />}>
              <Route path={"/pricing/clinic/:id"} />
            </Route>
            <Route path="/pricing/wellness_provider/" element={<PricingPlan />}>
              <Route path={"/pricing/wellness_provider/:id"} />
            </Route>
            <Route path="/exercise" element={<Exercise />} />
            <Route
              path="/patient-appointment-history"
              element={<PatientAppointmentHistory />}
            />
            <Route
              path={"/pricing/orthopedic_surgeon/"}
              element={<PricingPlan />}
            >
              <Route path={"/pricing/orthopedic_surgeon/:id"} />
            </Route>
            <Route
              path="/pricing/rehabilitation_center/"
              element={<PricingPlan />}
            />
            <Route path="/pricing/primary_care/" element={<PricingPlan />}>
              <Route path={"/pricing/primary_care/:id"} />
            </Route>
            <Route
              path="/pricing/physical_therapist/"
              element={<PricingPlan />}
            >
              <Route path={"/pricing/physical_therapist/:id"} />
            </Route>
            <Route path="/pricing/personal_trainer/" element={<PricingPlan />}>
              <Route path={"/pricing/personal_trainer/:id"} />
            </Route>
            <Route
              path="/pricing/nurse_practitioner/"
              element={<PricingPlan />}
            >
              <Route path={"/pricing/nurse_practitioner/:id"} />
            </Route>
            <Route path="/pricing/gym/" element={<PricingPlan />}>
              <Route path={"/pricing/gym/:id"} />
            </Route>
            <Route path="/provider-types" element={<AllProviders />} />
            <Route path="/pricing/athletic_trainers/" element={<PricingPlan />}>
              <Route path={"/pricing/athletic_trainers/:id"} />
            </Route>
            <Route path="/pricing/chiropractor/" element={<PricingPlan />}>
              <Route path={"/pricing/chiropractor/:id"} />
            </Route>
            <Route
              path="/pricing/pain_management_specialist"
              element={<PricingPlan />}
            >
              <Route path={"/pricing/pain_management_specialist/:id"} />
            </Route>
            <Route path="/pricing/massage_therapist/" element={<PricingPlan />}>
              <Route path={"/pricing/massage_therapist/:id"} />
            </Route>
            <Route path="/usertype" element={<AllUsers />} />
            <Route path="/provider-signup" element={<AllUsers />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/suppliers" element={<ComingSoon />} />
            <Route path="/pharmaceutical" element={<ComingSoon />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <ProviderDashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/Chiropractor"
              element={
                <RequireAuth>
                  <PhysicalTherapistProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/pain-management-specialist"
              element={
                <RequireAuth>
                  <ProviderProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/massage-therapist"
              element={
                <RequireAuth>
                  <WellnessProviderProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/messages"
              element={
                <RequireAuth>
                  <MessagePage />
                </RequireAuth>
              }
            />
            <Route
              path="/chats"
              element={
                <RequireAuth>
                  <ChatPage />
                </RequireAuth>
              }
            />
            <Route
              path="/personal-trainer-profile"
              element={
                <RequireAuth>
                  <PersonalTrainerProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/patient-profile"
              element={
                <RequireAuth>
                  <PatientProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/hospital-profile"
              element={
                <RequireAuth>
                  <HospitalProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/clinic-profile"
              element={
                <RequireAuth>
                  <ClinicProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/rehabilitation-center-profile"
              element={
                <RequireAuth>
                  <RehabilitationCenterProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/gym-profile"
              element={
                <RequireAuth>
                  <GymProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/athletic-trainers-profile"
              element={
                <RequireAuth>
                  <AthleticTrainersProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/physical-therapist-profile"
              element={
                <RequireAuth>
                  <PhysicalTherapistProfile />
                </RequireAuth>
              }
            />
            <Route path="evaluation" element={<Question />} />
            <Route
              path="evaluation/:muscleType/questions"
              element={<MuscleType />}
            />
            <Route path="exercises" element={<Exercises />} />
            <Route path="patient-exercise" element={<PatientExercises />} />
            <Route path="education" element={<EducationPage />} />
            <Route path="press/:id" element={<BlogPostContentnew />} />
            <Route path="education/:id" element={<EducationArticle />} />
            <Route path="exercise-details/:id" element={<ExerciseDetails />} />
            <Route
              path="provider-posts"
              element={
                <RequireAuth>
                  <Post />
                </RequireAuth>
              }
            />
            <Route path="provider-posts/:id" element={<BlogArticle />} />
            <Route
              path="/pat-dashboard"
              element={
                <RequireAuth>
                  <PatDashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/evaluation-history"
              element={
                <RequireAuth>
                  <PatientDashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/appointment-history"
              element={
                <RequireAuth>
                  <Appointment />
                </RequireAuth>
              }
            />
            <Route path="/about" element={<About />} />
            <Route path="/press" element={<BlogPost />} />
            <Route
              path="/referrals"
              element={
                <RequireAuth>
                  <Referrals />
                </RequireAuth>
              }
            />
            <Route
              path="/referrals/:id"
              element={
                <RequireAuth>
                  <Referrals />
                </RequireAuth>
              }
            />
            <Route
              path="/wellness-provider-profile"
              element={
                <RequireAuth>
                  <WellnessProviderProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/referrals-create"
              element={
                <RequireAuth>
                  <CreateReferral />
                </RequireAuth>
              }
            />
            <Route
              path="/provider-profile"
              element={
                <RequireAuth>
                  <ProviderProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/provider-claim-your-profile"
              element={<ClaimYourProfile />}
            />
            <Route
              path="provider-posts/add"
              element={
                <RequireAuth>
                  <AddBlog />
                </RequireAuth>
              }
            />
            <Route
              path="referral/:id"
              element={
                <RequireAuth>
                  <ReferralDetails />
                </RequireAuth>
              }
            />
            <Route
              path="provider-posts/edit/:id"
              element={
                <RequireAuth>
                  <AddBlog />
                </RequireAuth>
              }
            />
            <Route
              path="video"
              element={
                <RequireAuth>
                  <Video />
                </RequireAuth>
              }
            />
            <Route
              path="video/add"
              element={
                <RequireAuth>
                  <AddVideo />
                </RequireAuth>
              }
            />
            <Route
              path="video/edit/:id"
              element={
                <RequireAuth>
                  <AddVideo />
                </RequireAuth>
              }
            />
            <Route path="press-details/:id" element={<PressDetails />} />
            <Route path="providers" element={<Providers />} />
            <Route path="providers/:id" element={<ProviderDetails />} />
            <Route path="referral" element={<ProvidersReferral />} />
            <Route path="providers/:id/askAnExpert" element={<AskAnExpert />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="termsandconditions" element={<TermsAndConditions />} />
            <Route path="cookiepolicy" element={<Cookiepolicy />} />
            <Route path="advertisingpolicy" element={<AdvertisingPolicy />} />
            <Route path="reviewguidelines" element={<ReviewGuidelines />} />
            <Route
              path="/video/:videotitle"
              element={<EducationVideoPlayer />}
            />
            <Route path="/pricing" element={<PricingPlan />}></Route>
            <Route path="/provider/signup" element={<ProviderSubscription />} />
            <Route
              path="/provider/signup/agreement"
              element={<MasterServiceAgreement />}
            />
            <Route path="/provider/signup/success" element={<SuccessPage />} />
            <Route
              path="provider/subscriptions"
              element={
                <RequireAuth>
                  <Subscriptions />
                </RequireAuth>
              }
            />
            <Route
              path="/blog/:id"
              element={
                <Navigate
                  to={window?.location?.pathname?.replace(
                    "blog",
                    "provider-posts"
                  )}
                />
              }
            />
            <Route
              path="/blog/add"
              element={
                <Navigate
                  to={window?.location?.pathname?.replace(
                    "blog",
                    "provider-posts"
                  )}
                />
              }
            />
            <Route
              path="/blog"
              element={
                <Navigate
                  to={window?.location?.pathname?.replace(
                    "blog",
                    "provider-posts"
                  )}
                />
              }
            />
            <Route
              path="/blog/edit/:id"
              element={
                <Navigate
                  to={window?.location?.pathname?.replace(
                    "blog",
                    "provider-posts"
                  )}
                />
              }
            />
            <Route path="/patient-home" element={<ComingSoon />} />
            <Route path="/provider-home" element={<ComingSoon />} />
            <Route path="/procedures" element={<ComingSoon />} />
            <Route path="/404" element={<NotFound404 />} />
            {!(
              window?.location?.pathname === "/posts.xml" ||
              window?.location?.pathname === "/sitemap.xml" ||
              window?.location?.pathname === "/educations.xml" ||
              window?.location?.pathname === "/providers.xml"
            ) ? (
              <Route path="*" element={<Navigate to="/404" />} />
            ) : null}
          </Routes>
          <EducationPageModal />
          {window.location.pathname !== "/" &&
            window.location.pathname !== "/New-Home" &&
            window.location.pathname !== "/New-home" && <Footer />}
        </div>
      </Suspense>
    </div>
  );
};
const App = () => {
  return (
    <>
      <StoreProvider store={store}>
        <Data />
      </StoreProvider>
    </>
  );
};

export default App;
