import { action, thunk } from "easy-peasy";
import axios from "../util/axiosInst";

const model = {
  allProviders: [],
  conversations: [],
  totalUnreadMessages: 0,
  newRoom: null,
  chatUser: null,
  currentConversation: null,
  FooterShow: false,

  setRoomId: action((state, payload) => {
    state.chatRoomId = payload;
  }),
  setRoomType: action((state, payload) => {
    state.roomType = payload;
  }),
  setChatUserEmpty: action((state, payload) => {
    state.Userchatname = payload;
  }),
  createRoom: thunk(async (actions, payload) => {
    try {
      const {
        data: { chatRoom },
      } = await axios.post("/v1/chat/initiate", {
        userIds: [payload.providerId],
        type: "provider-to-provider",
      });
      actions.setNewRoom(chatRoom);
      actions.getConversationByRoomId({
        roomId: chatRoom.chatRoomId,
        name: payload?.name,
        roomType: "single",
      });
    } catch (error) {}
  }),

  createGroup: thunk(async (actions, payload) => {
    try {
      const {
        data: { chatRoom },
      } = await axios.post("/v1/chat/group/initiate", {
        userIds: payload?.userId,
        type: "provider-to-provider",
        groupName: payload?.name,
      });
      actions.setNewRoom(chatRoom);
      actions.getGroupConversationByRoomId(chatRoom);
    } catch (error) {}
  }),

  getGroupConversationByRoomId: thunk(async (actions, payload) => {
    try {
      const {
        data: { conversation, users },
      } = await axios.get(`/v1/chat/${payload?.chatRoomId}`);
      actions.setGroupInformation({
        conversation,
        groupDetails: payload,
        users,
      });
    } catch (error) {}
  }),

  setNewRoom: action((state, payload) => {
    state.newRoom = payload;
  }),

  setRecentConversations: action((state, payload) => {
    state.conversations = payload.conversation;
    state.totalUnreadMessages = payload.totalUnreadMessages;
  }),

  setGroupInformation: action((state, payload) => {
    let userData = JSON.parse(localStorage.getItem("mop-user"));
    state.currentConversation = payload?.conversation;
    state.chatUser = payload?.groupDetails;
    state.chatRoomId = payload?.groupDetails?.chatRoomId;
    const users = [];
    state.Users = payload?.users?.map((map) => {
      if (map.userId !== userData._id) {
        users.push(map.name);
      }
      return { id: map.userId, value: map.name };
    });
    state.Userchatname = {
      name: users.sort().toString(),
    };
    state.roomType = "group";
    state.GroupMembers = users?.sort();
  }),

  getRecentConversations: thunk(async (actions, payload) => {
    try {
      const {
        data: { conversation, totalUnreadMessages },
      } = await axios.get("/v1/chat");
      actions.setRecentConversations({
        conversation: conversation,
        totalUnreadMessages: totalUnreadMessages,
      });
      if (!payload?.fromWebsocket && payload.toString().length) {
        actions.getConversationByRoomId({
          roomId: conversation[payload].chatRoomId,
        });
      }
    } catch (error) {}
  }),
  addUserGroup: thunk(async (actions, payload) => {
    try {
      const { room } = await axios.post(
        `/v1/chat/${payload?.roomId}/add/user`,
        {
          userId: payload?.userId,
        }
      );
      actions.getConversationByRoomId({
        roomId: payload?.roomId,
        name: room?.roomId,
        photo: room?.roomImage,
      });
      actions.getRecentConversations();
    } catch (error) {}
  }),
  removeUserGroup: thunk(async (actions, payload) => {
    try {
      const { room } = await axios.post(
        `/v1/chat/${payload?.roomId}/remove/user`,
        {
          userId: payload?.userId,
        }
      );
      actions.getConversationByRoomId({
        roomId: payload?.roomId,
        name: room?.roomId,
        photo: room?.roomImage,
      });
      actions.getRecentConversations();
    } catch (error) {}
  }),

  getConversationByRoomId: thunk(async (actions, payload) => {
    try {
      const {
        data: { conversation, users },
      } = await axios.get(`/v1/chat/${payload?.roomId}`);
      actions.setConversationByRoomId({
        conversation: conversation,
        users: users,
        roomId: payload?.roomId,
        name: payload?.name,
        photo: payload?.photo,
        roomType: payload?.roomType,
      });
    } catch (error) {}
  }),

  setConversationByRoomId: action((state, payload) => {
    state.currentConversation = payload.conversation;
    state.chatRoomId = payload?.roomId;
    let userData = JSON.parse(localStorage.getItem("mop-user"));
    if (payload?.roomType) state.roomType = payload?.roomType;
    payload.users[1].chatType = "single";
    payload.users[0].chatType = "single";
    state.chatUser =
      payload.users[0].email ===
      JSON.parse(localStorage.getItem("mop-user")).email
        ? payload.users[1]
        : payload.users[0];

    const users = [];
    state.Users = payload?.users?.map((map) => {
      if (map.userId !== userData._id) {
        users.push(map.name);
      }
      return { id: map.userId, value: map.name };
    });
    state.Userchatname = {
      name: users.sort().toString(),
    };
    state.GroupMembers = users.sort();
  }),

  markAsRead: thunk(async (actions, payload) => {
    try {
      const { data } = await axios.put(`/v1/chat/${payload.roomId}/mark-read`);
      if (data.success) {
        actions.getRecentConversations(payload.index);
      }
    } catch (error) {}
  }),

  sendChatMessage: thunk(async (actions, payload) => {
    if (!payload.message || !payload?.message?.trim()) return;
    try {
      await axios.post(`/v1/chat/${payload.roomId}/message`, {
        message: payload.message,
        type: payload.type,
        title: payload.title,
      });
      actions.getConversationByRoomId({ roomId: payload.roomId });

      actions.getRecentConversations();
    } catch (error) {}
  }),

  deleteChatMessage: thunk(async (actions, payload) => {
    try {
      await axios.delete(`/v1/chat/message/${payload.id}`);
      actions.getRecentConversations(payload.length === 1 ? 0 : null);
      if (payload.length !== 1)
        actions.getConversationByRoomId({ roomId: payload.roomId });
    } catch (error) {}
  }),
};

export default model;
