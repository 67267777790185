import { action, thunk } from "easy-peasy";
import { isUndefined, reverse } from "lodash";
import axios from "../util/axiosInst";
import chat from "./chat";
import dashboard from "./dashboard";

const model = {
  chat,
  dashboard,
  image: null,
  questions: [],
  loggedInUser: null,
  currentQuestionNo: -1,
  correctAnswerCount: 0,
  selectedMuscleGroup: "",
  selectedMuscleGroupServerId: "",
  answers: {},
  muscleTypes: [],
  muscleGroups: [],
  educationArticles: [],
  providers: [],
  results: null,
  blogArticles: [],
  videoArticles: [],
  referrals: [],
  referralDetails: {},
  referralsPagination: {
    totalPages: 0,
    currentPageNo: 0,
  },
  referralsType: {
    type: "myReferrals",
  },

  policyDisclaimer: false,

  comment: "",
  comments: "",
  patientPastEvaluations: [],
  loading: false,
  providerMenu: false,
  moreMenu: false,
  latestContent: [],
  latestPoviderBlogs: [],
  blogCounts: [],
  videoCounts: [],
  educationRadioClicked: 1,
  blogCategories: [],
  patientappointmenthistory: {
    data: [],
    totalPages: 1,
    loading: false,
  },
  appointment: {
    data: [],
    totalPages: 1,
    loading: false,
  },
  videoCategories: [],
  blogPagination: {
    totalPages: 0,
    currentPageNo: 0,
  },
  videoPagination: {
    totalPages: 0,
    currentPageNo: 0,
  },
  appointmentPagination: {
    totalPages: 0,
    currentPageNo: 0,
  },
  providerList: [],
  educationPagination: {
    totalPages: 0,
    currentPageNo: 0,
  },
  providerPagination: {
    totalPages: 0,
    currentPageNo: 0,
  },
  messagePagination: {
    totalPages: 0,
    currentPageNo: 0,
  },
  messages: {
    messages: [],
    loading: false,
  },
  patientappointmenthistoryPagination: {
    totalPages: 0,
    currentPageNo: 0,
  },
  educationPageModal: {
    name: "",
    email: "",
    zipcode: "",
  },
  messageReplyModal: {
    name: "",
    userEmail: "",
    messsage: "",
    inboxId: "",
  },
  providerReferrals: {
    loading: true,
    data: [],
  },
  filterData: {
    name: "",
    type: "",
    zipcode: "",
    ratings: "",
    mop: "",
    page: 0,
    back: false,
  },
  pagination: {
    page: 0,
  },
  setEducationPageModal: action((state, payload) => {
    state.educationPageModal = payload;
  }),
  setFilterData: action((state, payload) => {
    state.filterData = payload;
  }),
  setPaginationData: action((state, payload) => {
    state.pagination = payload;
  }),
  setComment: action((state, payload) => {
    state.comment = payload;
  }),

  setComments: action((state, payload) => {
    state.comments = payload;
  }),

  setMessageReplyModal: action((state, payload) => {
    state.messageReplyModal = payload;
  }),

  setEducationRadioClicked: action((state, payload) => {
    state.educationRadioClicked = payload;
  }),

  increaseCorrectAnswerCount: action((state) => {
    state.correctAnswerCount += 1;
  }),

  setPolicyDisclaimer: action((state) => {
    state.policyDisclaimer = true;
  }),
  setProviders: action((state, payload) => {
    state.providers = payload;
  }),

  setCommonLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setlatestBlog: action((state, payload) => {
    state.latestPoviderBlogs = payload;
  }),
  setProviderPagination: action((state, payload) => {
    state.providerPagination["totalPages"] = payload.totalPages;
    state.providerPagination["currentPageNo"] = payload.page;
  }),

  setReferralType: action((state, payload) => {
    state.referralsType.type = payload;
  }),

  setReferralPageNo: action((state, payload) => {
    state.referralsPagination.currentPageNo = payload;
  }),

  nextQuestion: action((state) => {
    state.currentQuestionNo += 1;
  }),

  prevQuestion: action((state) => {
    state.currentQuestionNo -= 1;
  }),

  setPageNo: action((state, payload) => {
    state.educationPagination.currentPageNo = payload;
  }),

  setBlogPageNo: action((state, payload) => {
    state.blogPagination.currentPageNo = payload;
  }),

  setVideoPageNo: action((state, payload) => {
    state.videoPagination.currentPageNo = payload.page;
  }),
  setPatientAppointmentPageNo: action((state, payload) => {
    state.patientappointmenthistoryPagination.currentPageNo = payload;
  }),
  setAppointmentPageNo: action((state, payload) => {
    state.appointmentPagination.currentPageNo = payload;
  }),
  setProviderPageNo: action((state, payload) => {
    state.providerPagination.currentPageNo = payload;
  }),

  setMessagePageNo: action((state, payload) => {
    state.messagePagination.currentPageNo = payload;
  }),

  setResults: action((state, payload) => {
    state.results = payload;
  }),

  setLoggedInUser: action((state, payload) => {
    state.loggedInUser = payload;
  }),
  setMuscleType: action((state, payload) => {
    state.muscleTypes = payload;
  }),
  setProviderMenu: action((state, payload) => {
    state.providerMenu = payload;
  }),
  setMoreMenu: action((state, payload) => {
    state.moreMenu = payload;
  }),
  setMuscleGroup: action((state, payload) => {
    state.selectedMuscleGroup = payload.selectedMuscleGroup;
    state.selectedMuscleGroupServerId = payload.selectedMuscleGroupServerId;
    state.currentQuestionNo = 0;
    state.answers = {};
    state.summary = {};
  }),

  initEvaluationStore: action((state, payload) => {
    state.questions = [];
    state.currentQuestionNo = -1;
    state.correctAnswerCount = 0;
    state.selectedMuscleGroup = "";
    state.selectedMuscleGroupServerId = "";
    state.answers = {};
    state.summary = {};
    state.muscleGroups = [];
    state.results = null;
  }),

  setQuestions: action((state, payload) => {
    state.questions = payload;
  }),

  setAnswer: action((state, payload) => {
    if (payload.type === "radio") {
      state.answers[payload.prompt] = payload.answer;
      state.summary[payload.prompt] = payload.data.question.text;
    } else if (payload.type === "checkbox") {
      if (state.answers[payload.prompt]) {
        if (state.answers[payload.prompt].includes(payload.answer)) {
          state.answers[payload.prompt] = state.answers[payload.prompt].filter(
            (answer) => answer !== payload.answer
          );
          if (!state.answers[payload.prompt].length) {
            state.summary[payload.prompt] = state.summary[
              payload.prompt
            ].filter((text) => text !== payload.data.question.text);
          }
        } else {
          state.answers[payload.prompt].push(payload.answer);
          state.summary[payload.prompt].push(payload.data.question.text);
        }
      } else {
        state.answers[payload.prompt] = [payload.answer];
        state.summary[payload.prompt] = [payload.data.question.text];
      }
    } else if (payload.type === "slider") {
      state.answers[payload.prompt] = payload.answer;
      state.summary[payload.prompt] = payload.data.question.text;
    }
  }),

  setEducationArticles: action((state, payload) => {
    state.educationArticles = payload.results;
    state.educationPagination.totalPages = payload.totalPages;
  }),

  setLatestBlogs: action((state, payload) => {
    state.educationArticles = payload.results;
    state.educationPagination.totalPages = payload.totalPages;
  }),

  setBlogCounts: action((state, payload) => {
    state.blogCounts = payload?.blogCounts;
  }),

  setLatestContent: action((state, payload) => {
    state.latestContent = payload;
  }),

  setBlogArticles: action((state, payload) => {
    state.blogArticles = payload.results;
    state.blogPagination.totalPages = payload.totalPages;
  }),

  setBlogCategoryArticles: action((state, payload) => {
    state.blogArticles = payload.results;
    state.blogPagination.totalPages = payload.totalPages;
  }),

  setProviderBlogArticles: action((state, payload) => {
    state.blogArticles = payload.results;
    state.blogPagination.totalPages = payload.totalPages;
  }),

  setProviderVideoArticles: action((state, payload) => {
    state.videoArticles = payload.results;
    state.videoPagination.totalPages = payload.totalPages;
  }),

  setProviderReferrals: action((state, payload) => {
    state.providerReferrals.data = payload.results;
    state.providerReferrals.loading = false;
  }),

  setReferalDetails: action((state, payload) => {
    state.referralDetails = payload;
  }),

  setBlogCategories: action((state, payload) => {
    state.blogCategories = payload.results;
  }),

  setPatientEvaluationHistory: action((state, payload) => {
    state.patientPastEvaluations = payload.results;
    state.loading = false;
  }),
  setAppointmentHistory: action((state, payload) => {
    state.appointment.data = payload.data;
    state.appointment.totalPages = payload.totalPages;
    state.appointment.loading = false;
  }),
  setPatientAppointmentHistory: action((state, payload) => {
    state.patientappointmenthistory.data = payload.data;
    state.patientappointmenthistory.loading = payload.loading;
    state.patientappointmenthistory.totalPages = payload.totalPages;
  }),
  setReferralOthers: action((state, payload) => {
    state.referrals = payload.results;
    state.referralsPagination.totalPages = payload.pagination?.totalCount / 10;
  }),

  setImage: action((state, payload) => {
    state.image = payload;
  }),
  setMessages: action((state, payload) => {
    state.messages.messages = payload.results;
    state.messages.loading = false;
    state.messagePagination.totalPages = payload.totalPages;
  }),
  setMessagesLoading: action((state) => {
    state.messages.loading = true;
  }),

  setRandomProvidersList: action((state, payload) => {
    state.providerList = payload;
  }),

  setProviderLoginData: action((state, payload) => {
    state.providerLoginData = payload;
  }),

  getEducationArticles: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    let params = {
      active: true,
      title: payload.query,
      limit: 8,
      sortBy: "-updatedAt",
      page: payload?.pageNo || state.educationPagination.currentPageNo + 1,
      evaluationTypeId: payload.id,
      search: payload.search,
    };
    try {
      if (payload.search === "") {
        delete params.search;
      }
      const { data } = await axios.get("/v1/content", { params });
      actions.setEducationArticles(data);
    } catch (error) {}
  }),
  getMuscleTypes: thunk(async (actions) => {
    try {
      const { data } = await axios.get("/v1/public/evaluation/type");
      actions.setMuscleType(data);
    } catch (error) {}
  }),
  getLatestBlogs: thunk(async (actions) => {
    try {
      const { data } = await axios.get("/v1/public/blogs/latest");
      actions.setlatestBlog(data);
    } catch (error) {}
  }),

  getBlogCounts: thunk(async (actions) => {
    try {
      const { data } = await axios.get("/v1/public/blogs/count");
      actions.setBlogCounts(data);
    } catch (error) {}
  }),
  getLoggedinUsers: thunk(async (actions, payload) => {
    const { data: providerData } = await axios.get(
      `/v1/provider/profile/${payload}`
    );
    actions.setLoggedInUser(providerData);
  }),

  getLoggedinPatient: thunk(async (actions, payload) => {
    const { data: providerData } = await axios.get(`v1/patient/-/${payload}`);
    actions.setLoggedInUser(providerData);
  }),

  getLatestContent: thunk(async (actions) => {
    try {
      const { data } = await axios.get("/v1/public/contents/latest");
      actions.setLatestContent(data);
    } catch (error) {}
  }),

  getBlogArticles: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    let params = {
      search: payload?.search,
      type: payload?.type,
      limit: 8,
      categoryId: payload?.id,
      page: payload?.pageNo || state.blogPagination.currentPageNo + 1,
    };
    try {
      if (payload.search === "") {
        delete params.search;
      }
      if (payload.type === "") {
        delete params.type;
      }
      const { data } = await axios.get("/v1/public/posts", { params });
      actions.setBlogArticles(data);
    } catch (error) {}
  }),

  getBlogCategoryArticles: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    try {
      const { data } = await axios.get("/v1/public/posts", {
        params: {
          categoryId: payload?.id,
          limit: 10,
          page: state.blogPagination.currentPageNo + 1,
        },
      });
      actions.setBlogCategoryArticles(data);
    } catch (error) {}
  }),

  getProviderBlogArticles: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    actions.setProviderBlogArticles({
      results: "",
      totalPages: state.blogPagination.totalPages,
    });
    actions.setCommonLoading(true);
    try {
      const { data } = await axios.get("/v1/provider/blogs", {
        params: {
          search: payload?.search,
          limit: 10,
          page: state.blogPagination.currentPageNo + 1,
          type: payload?.type,
          sortBy: `createdAt:${payload.sort}`,
        },
      });
      actions.setProviderBlogArticles(data);
      actions.setCommonLoading(false);
    } catch (error) {}
  }),

  getProviderVideoArticles: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    try {
      actions.setProviderVideoArticles({
        results: "",
        totalPages: state.videoPagination.totalPages,
      });
      actions.setCommonLoading(true);
      const { data } = await axios.get("/v1/exercise", {
        params: {
          search: payload.search,
          limit: 10,
          page: isUndefined(payload.page)
            ? state.videoPagination.currentPageNo + 1
            : payload.page,
          sortBy: `createdAt:${payload.sort}`,
          type: payload?.type,
        },
      });
      actions.setVideoPageNo(data);
      actions.setProviderVideoArticles(data);
      actions.setCommonLoading(false);
    } catch (error) {}
  }),

  getReferalDetails: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(`/v1/referral/${payload}`);
      if (response.status === 200) {
        actions.setReferalDetails(response.data);
      }
    } catch (error) {}
  }),

  getPatientEvaluationHistory: thunk(async (actions, payload) => {
    const { patientId } = payload;
    try {
      const { data } = await axios.get(
        `v1/evaluation/history?patientId=${patientId}`,
        {
          params: {
            limit: 10,
            page: 1,
            sortBy: "createdAt",
          },
        }
      );
      actions.setPatientEvaluationHistory({
        ...data,
        results: reverse(data.results),
      });
    } catch (error) {}
  }),

  getAppointmentHistory: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    const { email } = payload;
    try {
      const { data } = await axios.get(`/v1/patient/appointments`, {
        params: {
          email: email.toString(),
          limit: 10,
          page: state.appointmentPagination.currentPageNo + 1,
          sortBy: "createdAt:desc",
        },
      });
      actions.setAppointmentHistory({
        data: data?.docs,
        totalPages: data.totalPages,
      });
    } catch (error) {}
  }),

  getPatientAppointmentHistory: thunk(
    async (actions, payload, { getState }) => {
      const { providerId, status } = payload;
      const state = getState();
      actions.setPatientAppointmentHistory({
        loading: true,
      });
      try {
        const { data } = await axios.get(
          `v1/provider/${providerId}/appointments?providerId=${providerId}`,
          {
            params: {
              limit: 10,
              page: state.patientappointmenthistoryPagination.currentPageNo + 1,
              sortBy: "createdAt:desc",
              status,
            },
          }
        );
        actions.setPatientAppointmentHistory({
          data: data?.docs,
          loading: false,
          totalPages: data.totalPages,
        });
      } catch (error) {}
    }
  ),
  getBlogCategories: thunk(async (actions) => {
    try {
      const { data } = await axios.get("/v1/public/blog/categories/no-blogs", {
        params: {},
      });
      actions.setBlogCategories(data);
    } catch (error) {}
  }),

  getProviders: thunk(async (actions, payload, { getState }) => {
    actions.setProviders([]);
    if (!payload) {
      payload = {};
    }
    for (let key in payload) {
      if (payload[key] === "") {
        delete payload[key];
      } else if (
        key !== "mopRecommended" &&
        key !== "ratings" &&
        key !== "limit" &&
        key !== "page"
      ) {
        payload[key] = `${payload[key]}`;
      }
    }

    if (payload.ratings) {
      payload.rating = payload.ratings;
      delete payload.ratings;
    }
    if (payload.address) {
      delete payload.address;
    }
    let limit = 10;
    if (payload?.limit) {
      limit = payload?.limit;
    } else {
      limit = 10;
    }

    try {
      actions.setCommonLoading(true);
      const { data } = await axios.get("/v1/public/providers", {
        params: {
          limit: limit,
          active: true,
          publish: true,
          hide: false,
          page: 2,
          ...payload,
        },
      });
      actions.setCommonLoading(false);
      actions.setProviders(data.results);
      actions.setProviderPagination(data);
    } catch (error) {}
  }),

  randomProvidersList: thunk(async (actions) => {
    try {
      const { data } = await axios.get(`/v1/provider/list`);
      actions.setRandomProvidersList(data);
    } catch (error) {}
  }),

  getMessages: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    let userId = JSON.parse(localStorage.getItem("mop-user")).id;
    try {
      actions.setMessagesLoading();
      const { data } = await axios.get(
        `/v1/message/${userId}?populate=replies`,
        {
          params: {
            title: payload,
            limit: 10,
            page: state.messagePagination.currentPageNo + 1,
            sortBy: "createdAt:desc",
          },
        }
      );
      actions.setMessages(data);
    } catch (error) {}
  }),

  getImage: thunk(async (actions, payload) => {
    try {
      const { data } = await axios.get("/v1/public/image", {
        params: {
          path: payload,
        },
      });
      actions.setImage(data);
    } catch (error) {}
  }),

  getReferralOthers: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    actions.setCommonLoading(true);
    actions.setReferralOthers({ results: "", pagination: "" });
    try {
      const paramsObj = {
        limit: 10,
        page: state.referralsPagination.currentPageNo + 1,
        search: payload,
      };
      if (state.referralsType.type !== "myReferrals") {
        paramsObj.type = state.referralsType.type;
      }

      const { data } = await axios.get(`/v1/referral/list`, {
        params: paramsObj,
      });
      actions.setReferralOthers(data);
      actions.setCommonLoading(false);
    } catch (error) {}
  }),

  getProviderReferrals: thunk(async (actions) => {
    try {
      const { data } = await axios.get("/v1/provider/all", {
        params: {
          active: 1,
          publish: 1,
          referral: true,
        },
      });
      actions.setProviderReferrals(data);
    } catch (error) {}
  }),
};

export default model;
