import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  loggedInUserNameWithoutSplit,
  loggedInUserNameWithSplit,
} from "../../../util/utils";
import Image from "../../Image";
import OffCanvasExample from "./OffCanvasExample";
import axios from "../../../util/axiosInst";

export const subscription = async () => {
  window.location.href = "/provider/subscriptions";
};

const LoggedInMenuNew = (props) => {
  const user = JSON.parse(localStorage.getItem("mop-user"));
  const [provider, setProvider] = useState(null);

  // getting provider details
  const getProvider = async (loggedInUserId) => {
    if (!loggedInUserId) return;
    try {
      const { data: providerData } = await axios.get(
        `/v1/provider/profile/${loggedInUserId}`
      );
      setProvider(providerData);
    } catch (e) {}
  };

  useEffect(() => {
    if (user?.role !== "patient") getProvider(user?.id);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-100 px-md-0 mt-3 mt-sm-0 portal-nav  d-flex">
      <div className="dropdown d-flex">
        <div className="d-flex mt-2">
          <div className="me-2 ">
            {provider?.photo?.includes(
              "https://dev-mop.s3.us-east-2.amazonaws.com//"
            ) || !provider?.photo?.length ? (
              <Image
                src={provider?.photo}
                type="provider"
                className="rounded-circle w-100 profile-img"
                alt="Login"
                height="50"
              />
            ) : (
              <Image
                src={provider?.photo}
                type="provider"
                className="rounded-circle w-100 profile-img"
                height="50"
                alt="ProviderLogin"
              />
            )}
          </div>

          {window.innerWidth < 1000 ? (
            <span className="pe-2 ps-1 fw-semibold">
              <span>{loggedInUserNameWithoutSplit(provider, user)}</span>
            </span>
          ) : (
            <>
              <span
                data-tip
                data-for="name"
                className="text-wrap d-block wordBreak-break-word"
              >
                {loggedInUserNameWithSplit(provider, user)}
              </span>
              <ReactTooltip id="name" place="right" effect="solid">
                <span>{loggedInUserNameWithoutSplit(provider, user)}</span>
              </ReactTooltip>
            </>
          )}
        </div>
      </div>
      <div className="d-md-flex ms-2 d-none">
        <OffCanvasExample placement={"end"} name={"end"} />
      </div>
    </div>
  );
};

export default LoggedInMenuNew;
