import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logOutUser, renderLink } from "../../../util/utils";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Dropdown, Popover } from "react-bootstrap";

const LoggedInSideBar = (props) => {
  const user = JSON.parse(localStorage.getItem("mop-user"));

  const enable =
    !!user.isPaid === true
      ? user.role !== "patient"
        ? false
        : true
      : user.role !== "patient"
      ? true
      : false;

  const location = useLocation();
  const navigate = useNavigate();
  const membership = sessionStorage.getItem("membershipDetails")
    ? JSON.parse(sessionStorage.getItem("membershipDetails"))
    : {};

  const onIdle = () => logOutUser();

  useIdleTimer({ onIdle, timeout: 1000 * 60 * 30 });
  //update the count of feed when user see all the feed.

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Premium feature, click button below to Upgrade Plan.
      </Popover.Body>
    </Popover>
  );

  const LinkTag = ({ id, children, title }) =>
    enable ? (
      <OverlayTrigger placement="top" overlay={popover}>
        <p className="m-0 width-fit-content">{children}</p>
      </OverlayTrigger>
    ) : (
      <p className="m-0 width-fit-content">{children}</p>
    );
  return (
    <div className="row">
      <div className="col portal-nav align-self-center">
        <nav
          className="mb-0 mt-3 mt-md-0 d-block justify-content-md-end"
          style={{ lineHeight: props.lineHeight }}
        >
          {user?.role !== "patient" && (
            <h5 className="ps-md-1 ps-2 mb-3 text-primary">
              <b>Current Plan:</b>{" "}
              <span className="text-capitalize text-white bg-primary rounded px-3 ms-1 py-1 ">
                {user.planType || "Basic"}
              </span>
            </h5>
          )}
          <Dropdown>
            <Dropdown.Toggle
              variant="white"
              id="dropdown-basic"
              className={"pt-0  d-md-block d-none ps-1 mb-2 fw-semibold"}
              onClick={() => props.setSelect(!props.select)}
            >
              Settings
            </Dropdown.Toggle>
            <Dropdown.Toggle
              variant="white"
              id="dropdown-basic"
              className={
                "pt-0 ps-md-1 d-md-none d-block fs-6 ps-2 ms-1 fw-semibold"
              }
              onClick={() => props.setSelect(!props.select)}
            >
              Settings
            </Dropdown.Toggle>
          </Dropdown>

          {props.select && (
            <>
              {user?.role !== "patient" ? (
                <div className="px-md-4 px-2 d-block">
                  <span className="blockBackDropMobile px-1 py-2">
                    <Link
                      to={renderLink(user)}
                      className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold cursor-pointer ${
                        location.pathname.includes("profile")
                          ? "text-primary"
                          : "text-black"
                      }`}
                      onClick={() => props.handleClose()}
                    >
                      Basic Info
                    </Link>
                  </span>
                  <span className="blockBackDropDesktop">
                    {" "}
                    <Link
                      to={renderLink(user)}
                      className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold cursor-pointer ${
                        location.pathname.includes("profile")
                          ? "text-primary"
                          : "text-black"
                      }`}
                      onClick={() => props.handleClose()}
                    >
                      Basic Info
                    </Link>
                  </span>
                  <span className="blockBackDropMobile px-1 py-2">
                    <Link
                      to={"/reset-password"}
                      className={`text-decoration-none px-2 d-block fw-semibold ${
                        location.pathname === "/reset-password"
                          ? "text-primary"
                          : "text-black"
                      }`}
                      onClick={() => props.handleClose()}
                    >
                      Reset Password
                    </Link>
                  </span>
                  <span className="blockBackDropDesktop">
                    <Link
                      to={"/reset-password"}
                      className={`text-decoration-none px-2 d-block fw-semibold ${
                        location.pathname === "/reset-password"
                          ? "text-primary"
                          : "text-black"
                      }`}
                      onClick={() => props.handleClose()}
                    >
                      Reset Password
                    </Link>
                  </span>

                  <span className="blockBackDropMobile px-1 py-2">
                    <Link
                      to={"/provider/subscriptions"}
                      className={`text-decoration-none px-2 d-block fw-semibold  location.pathname === "/provider-home"
                      ? "text-primary"
                      : "text-black"`}
                      onClick={() => props.handleClose()}
                    >
                      Subscription
                    </Link>
                  </span>
                  <span className="blockBackDropDesktop">
                    <Link
                      to={"/provider/subscriptions"}
                      className={`text-decoration-none px-2 d-block fw-semibold ${
                        location.pathname === "/provider-home"
                          ? "text-primary"
                          : "text-black"
                      }`}
                      onClick={() => props.handleClose()}
                    >
                      Subscription
                    </Link>
                  </span>
                  <span className="blockBackDropMobile px-1 py-2">
                    <Link
                      to={"#"}
                      className="mb-md-0 mb-1 text-decoration-none px-2 fw-semibold text-black"
                      {...props}
                      onClick={() => {
                        logOutUser();
                        props.handleClose();
                      }}
                    >
                      Logout
                    </Link>
                  </span>

                  <span className="blockBackDropDesktop">
                    <Link
                      className="mb-md-0 mb-1 text-decoration-none px-2 fw-semibold text-black"
                      {...props}
                      onClick={() => {
                        props.handleClose();
                        logOutUser();
                      }}
                    >
                      Logout
                    </Link>
                  </span>
                </div>
              ) : (
                <div className="px-4 d-block">
                  <span className="blockBackDropMobile px-1 py-2">
                    <LinkTag id="home" title="">
                      <Link
                        to={"/patient-profile"}
                        className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                          location.pathname.includes("profile")
                            ? "text-primary"
                            : "text-black"
                        }`}
                        onClick={() => {
                          props.handleClose();
                        }}
                      >
                        Profile
                      </Link>
                    </LinkTag>
                  </span>

                  <span className="blockBackDropDesktop">
                    <LinkTag id="home" title="">
                      {" "}
                      <Link
                        to={"/patient-profile"}
                        className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold cursor-pointer ${
                          location.pathname.includes("profile")
                            ? "text-primary"
                            : "text-black"
                        }`}
                        onClick={() => props.handleClose()}
                      >
                        Profile
                      </Link>
                    </LinkTag>
                  </span>
                  <span className="blockBackDropMobile px-1 py-2">
                    <Link
                      to={"/reset-password"}
                      className={`text-decoration-none px-2 d-block fw-semibold ${
                        location.pathname === "/reset-password"
                          ? "text-primary"
                          : "text-black"
                      }`}
                      onClick={() => props.handleClose()}
                    >
                      Reset Password
                    </Link>
                  </span>
                  <span className="blockBackDropDesktop">
                    <Link
                      to={"/reset-password"}
                      className={`text-decoration-none px-2 d-block fw-semibold ${
                        location.pathname === "/reset-password"
                          ? "text-primary"
                          : "text-black"
                      }`}
                      onClick={() => props.handleClose()}
                    >
                      Reset Password
                    </Link>
                  </span>
                  <span className="blockBackDropMobile px-1 py-2">
                    <Link
                      to={"#"}
                      className="mb-md-0 mb-1 text-decoration-none px-2 fw-semibold text-black"
                      {...props}
                      onClick={() => {
                        logOutUser();
                        props.handleClose();
                      }}
                    >
                      Logout
                    </Link>
                  </span>

                  <span className="blockBackDropDesktop">
                    <Link
                      to={"#"}
                      className="  mb-md-0 mb-1 text-decoration-none px-2 fw-semibold text-black"
                      {...props}
                      onClick={() => {
                        logOutUser();
                        props.handleClose();
                      }}
                    >
                      Logout
                    </Link>
                  </span>
                </div>
              )}
            </>
          )}
          {/* *************Home*********** */}
          <span className="blockBackDropMobile px-1 py-2">
            <Link
              to={user?.role === "patient" ? "/pat-dashboard" : "/dashboard"}
              className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold cursor-pointer ${
                location.pathname === "/dashboard" ||
                location.pathname === "/pat-dashboard"
                  ? "text-primary"
                  : "text-black"
              }`}
              onClick={() => props.handleClose()}
            >
              Home
            </Link>
          </span>

          <span className="blockBackDropDesktop">
            <Link
              to={user?.role === "patient" ? "/pat-dashboard" : "/dashboard"}
              className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold cursor-pointer width-fit-content ${
                location.pathname === "/dashboard" ||
                location.pathname === "/pat-dashboard"
                  ? "text-primary"
                  : "text-black"
              }`}
              onClick={() => props.handleClose()}
            >
              Home
            </Link>
          </span>
          {/* *************Home End*********** */}

          {/* *************Profile *********** */}
          {user.role !== "patient" && (
            <>
              {" "}
              <span className="blockBackDropMobile px-1 py-2">
                <Link
                  to={`/providers/${user.slug}`}
                  className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold cursor-pointer ${
                    location.pathname.includes(`/providers/${user.slug}`)
                      ? "text-primary"
                      : "text-black"
                  }`}
                  onClick={() => props.handleClose()}
                >
                  Profile
                </Link>
              </span>
              <span className="blockBackDropDesktop">
                <Link
                  to={`/providers/${user.slug}`}
                  className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold cursor-pointer ${
                    location.pathname.includes(`/providers/${user.slug}`)
                      ? "text-primary"
                      : "text-black"
                  }`}
                  onClick={() => props.handleClose()}
                >
                  Profile
                </Link>
              </span>
            </>
          )}
          {/* *************Profile End*********** */}

          {/* *************Evaluation*********** */}
          {user?.role === "patient" && (
            <>
              <span className="blockBackDropMobile px-1 py-2">
                <LinkTag id="Evaluation" title="">
                  <Link
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/evaluation-history"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    to={enable ? "#" : "/evaluation-history"}
                    onClick={() => props.handleClose()}
                  >
                    {" "}
                    Evaluation History
                  </Link>
                </LinkTag>
              </span>
              <span className="blockBackDropDesktop">
                <LinkTag id="evaluation" title="">
                  {" "}
                  <Link
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/evaluation-history"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    to={enable ? "#" : "/evaluation-history"}
                    onClick={() => props.handleClose()}
                  >
                    {" "}
                    Evaluation History
                  </Link>
                </LinkTag>
              </span>
            </>
          )}
          {/* *************Evaluation End*********** */}

          {/* *************Appointment history*********** */}
          {user?.role === "patient" && (
            <>
              <span className="blockBackDropMobile px-1 py-2">
                <LinkTag id="appointment">
                  <Link
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/appointment-history"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    to={enable ? "#" : "/appointment-history"}
                    onClick={() => props.handleClose()}
                  >
                    {" "}
                    Appointment History
                  </Link>
                </LinkTag>
              </span>
              <span className="blockBackDropDesktop">
                <LinkTag id="appointment" title="">
                  {" "}
                  <Link
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/appointment-history"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    to={enable ? "#" : "/appointment-history"}
                    onClick={() => props.handleClose()}
                  >
                    Appointment History
                  </Link>
                </LinkTag>
              </span>
            </>
          )}
          {/* *************Appointment History End*********** */}

          {/* *************Referral*********** */}
          {user?.role !== "patient" && (
            <>
              <span className="blockBackDropMobile px-1 py-2">
                <LinkTag id="referral" title="">
                  {" "}
                  <Link
                    to={
                      enable
                        ? "#"
                        : user?.role === "provider"
                        ? "/referrals"
                        : "/dashboard"
                    }
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/referrals"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    onClick={() => props.handleClose()}
                  >
                    Referrals
                  </Link>
                </LinkTag>
              </span>
              <span className="blockBackDropDesktop">
                <LinkTag id="referral" title="">
                  {" "}
                  <Link
                    to={
                      enable
                        ? "#"
                        : user?.role === "provider"
                        ? "/referrals"
                        : "/dashboard"
                    }
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/referrals"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    onClick={() => {
                      props.handleClose();
                    }}
                  >
                    Referrals
                  </Link>
                </LinkTag>
              </span>
            </>
          )}
          {/* *************Referral End*********** */}

          {/* *************Patient Appointment History *********** */}
          {user?.role !== "patient" && (
            <>
              <span className="blockBackDropMobile px-1 py-2">
                <LinkTag id="Patient" title="">
                  {" "}
                  <Link
                    to={enable ? "#" : "/patient-appointment-history"}
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/patient-appointment-history"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    onClick={(e) => {
                      props.handleClose();
                    }}
                  >
                    Appointment Requests
                  </Link>
                </LinkTag>
              </span>
              <span className="blockBackDropDesktop">
                <LinkTag id="patient-appointment" title="">
                  {" "}
                  <Link
                    to={enable ? "#" : "/patient-appointment-history"}
                    className={`py-3 py-lg-0 d-flex textAlign-inherit text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/patient-appointment-history"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    onClick={() => {
                      props.handleClose();
                    }}
                  >
                    Appointment Requests
                  </Link>
                </LinkTag>
              </span>
            </>
          )}
          {/* *************Patient Appointment History End*********** */}

          {/* *************Chat*********** */}
          {user?.role !== "patient" && (
            <>
              <span className="blockBackDropMobile px-1 py-2">
                <LinkTag id="chat" title="">
                  <Link
                    to={
                      enable
                        ? "#"
                        : user?.role === "provider"
                        ? "/chats"
                        : "/dashboard"
                    }
                    onClick={() => props.handleClose()}
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/chats"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                  >
                    Chat
                  </Link>
                </LinkTag>
              </span>

              <span className="blockBackDropDesktop">
                <LinkTag id="chat" title="">
                  {" "}
                  <Link
                    to={
                      enable
                        ? "#"
                        : user?.role === "provider"
                        ? "/chats"
                        : "/dashboard"
                    }
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/chats"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    onClick={() => {
                      props.handleClose();
                    }}
                  >
                    Chat
                  </Link>
                </LinkTag>
              </span>
            </>
          )}
          {/* *************Chat End*********** */}

          {/* *************My Exercises *********** */}
          {user?.role === "patient" && (
            <>
              <span className="blockBackDropMobile px-1 py-2">
                <LinkTag id="my-exercise" title="">
                  <Link
                    to={enable ? "#" : "/patient-exercise"}
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/patient-exercise"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    onClick={(e) => props.handleClose()}
                  >
                    My Exercises
                  </Link>
                </LinkTag>
              </span>
              <span className="blockBackDropDesktop">
                <LinkTag id="my-exercises" title="">
                  <Link
                    to={enable ? "#" : "/patient-exercise"}
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/patient-exercise"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    onClick={(e) => {
                      props.handleClose();
                    }}
                  >
                    My Exercises
                  </Link>
                </LinkTag>
              </span>
            </>
          )}
          {/* *************My Exercises End*********** */}

          {/* *************Dashboard*********** */}
          <span className="blockBackDropMobile px-1 py-2">
            <LinkTag id="posts" title="">
              <Link
                to={
                  enable
                    ? "#"
                    : user?.role !== "patient"
                    ? "/provider-home"
                    : "/patient-home"
                }
                className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                  location.pathname === "/provider-home" ||
                  location.pathname === "/patient-home"
                    ? "text-primary"
                    : enable
                    ? "text-muted"
                    : "text-black"
                } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                onClick={() => props.handleClose()}
              >
                Analytics
              </Link>
            </LinkTag>
          </span>
          <span className="blockBackDropDesktop">
            <LinkTag id="posts" title="">
              <Link
                to={
                  enable
                    ? "#"
                    : user?.role !== "patient"
                    ? "/provider-home"
                    : "/patient-home"
                }
                className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                  location.pathname === "/provider-home" ||
                  location.pathname === "/patient-home"
                    ? "text-primary"
                    : enable
                    ? "text-muted"
                    : "text-black"
                } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                onClick={() => {
                  props.handleClose();
                }}
              >
                Analytics
              </Link>
            </LinkTag>
          </span>
          {/* *************Dashboard End*********** */}

          {/* *************Posts *********** */}
          {JSON.stringify(membership) === "{}" || membership?.blogs ? (
            <>
              {user?.role !== "patient" && (
                <>
                  <span className="blockBackDropMobile px-1 py-2">
                    <LinkTag id="posts" title="">
                      <Link
                        to={enable ? "#" : "/provider-posts"}
                        className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                          location.pathname === "/provider-posts"
                            ? "text-primary"
                            : enable
                            ? "text-muted"
                            : "text-black"
                        } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                        onClick={() => props.handleClose()}
                      >
                        Posts
                      </Link>
                    </LinkTag>
                  </span>
                  <span className="blockBackDropDesktop">
                    <LinkTag id="posts" title="">
                      <Link
                        to={enable ? "#" : "/provider-posts"}
                        className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                          location.pathname === "/provider-posts"
                            ? "text-primary"
                            : enable
                            ? "text-muted"
                            : "text-black"
                        } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                        onClick={() => {
                          props.handleClose();
                        }}
                      >
                        Posts
                      </Link>
                    </LinkTag>
                  </span>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {/* *************Posts End*********** */}

          {/* *************Exercises *********** */}
          {user?.role !== "patient" && (
            <>
              <span className="blockBackDropMobile px-1 py-2">
                {" "}
                <LinkTag id="exercises" title="">
                  {" "}
                  <Link
                    to={enable ? "#" : "/exercise"}
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/exercise"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    onClick={(e) => {
                      props.handleClose();
                    }}
                  >
                    Exercises
                  </Link>
                </LinkTag>
              </span>
              <span className="blockBackDropDesktop">
                <LinkTag id="exercises" title="">
                  {" "}
                  <Link
                    to={enable ? "#" : "/exercise"}
                    className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                      location.pathname === "/exercise"
                        ? "text-primary"
                        : enable
                        ? "text-muted"
                        : "text-black"
                    } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                    onClick={(e) => {
                      props.handleClose();
                    }}
                  >
                    {" "}
                    Exercises
                  </Link>
                </LinkTag>
              </span>
            </>
          )}
          {/* *************Exercises End*********** */}

          {/* *************Procedures Classes *********** */}
          {user?.role !== "patient" &&
            (user.userType === "orthopedic_surgeon" ||
              user.userType === "neuro_surgeon") && (
              <>
                <span className="blockBackDropMobile px-1 py-2">
                  {" "}
                  <LinkTag id="procedures" title="">
                    {" "}
                    <Link
                      to={enable ? "#" : "/procedures"}
                      className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                        location.pathname === "/procedures"
                          ? "text-primary"
                          : enable
                          ? "text-muted"
                          : "text-black"
                      } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                      onClick={(e) => {
                        props.handleClose();
                      }}
                    >
                      Procedures
                    </Link>
                  </LinkTag>
                </span>
                <span className="blockBackDropDesktop">
                  <LinkTag id="procedures" title="">
                    {" "}
                    <Link
                      to={enable ? "#" : "/procedures"}
                      className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                        location.pathname === "/procedures"
                          ? "text-primary"
                          : enable
                          ? "text-muted"
                          : "text-black"
                      } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                      onClick={(e) => {
                        props.handleClose();
                      }}
                    >
                      {" "}
                      Procedures
                    </Link>
                  </LinkTag>
                </span>
              </>
            )}
          {user?.role !== "patient" &&
            (user.userType === "athletic_trainers" ||
              user.userType === "personal_trainer" ||
              user.userType === "gym") && (
              <>
                <span className="blockBackDropMobile px-1 py-2">
                  {" "}
                  <LinkTag id="procedures" title="">
                    {" "}
                    <Link
                      to={enable ? "#" : "/procedures"}
                      className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                        location.pathname === "/procedures"
                          ? "text-primary"
                          : enable
                          ? "text-muted"
                          : "text-black"
                      } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                      onClick={(e) => {
                        props.handleClose();
                      }}
                    >
                      Classes
                    </Link>
                  </LinkTag>
                </span>
                <span className="blockBackDropDesktop">
                  <LinkTag id="procedures" title="">
                    {" "}
                    <Link
                      to={enable ? "#" : "/procedures"}
                      className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                        location.pathname === "/procedures"
                          ? "text-primary"
                          : enable
                          ? "text-muted"
                          : "text-black"
                      } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                      onClick={(e) => {
                        props.handleClose();
                      }}
                    >
                      {" "}
                      Classes
                    </Link>
                  </LinkTag>
                </span>
              </>
            )}
          {/* *************Procedures Classes End*********** */}

          {user?.role !== "patient" &&
            user?.userType !== "primary_care" &&
            user?.userType !== "nurse_practitioner" &&
            user?.userType !== "orthopedic_surgeon" && (
              <>
                <span className="blockBackDropMobile px-1 py-2">
                  <LinkTag id="message" title="">
                    {" "}
                    <Link
                      to={enable ? "#" : "/messages"}
                      className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                        location.pathname === "/messages"
                          ? "text-primary"
                          : enable
                          ? "text-muted"
                          : "text-black"
                      } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                      onClick={() => props.handleClose()}
                    >
                      Messages
                    </Link>
                  </LinkTag>
                </span>

                <span className="blockBackDropDesktop">
                  <LinkTag id="message" title="">
                    {" "}
                    <Link
                      to={enable ? "#" : "/messages"}
                      className={`py-3 py-lg-0 text-decoration-none px-2 fw-semibold ${
                        location.pathname === "/messages"
                          ? "text-primary"
                          : enable
                          ? "text-muted"
                          : "text-black"
                      } ${enable ? "cursor-no-drop" : "cursor-pointer"}`}
                      onClick={() => {
                        props.handleClose();
                      }}
                    >
                      Messages
                    </Link>
                  </LinkTag>
                </span>
              </>
            )}

          {(!user.planType || user.planType === "basic") &&
            user.role !== "patient" && (
              <button
                className="btn btn-primary mx-2 p-2"
                onClick={() => {
                  props.handleClose();
                  navigate("/provider/subscriptions", {
                    state: user?.userType,
                  });
                }}
              >
                Upgrade
              </button>
            )}
        </nav>
      </div>
    </div>
  );
};

export default LoggedInSideBar;
