import { useStoreActions, useStoreState } from "easy-peasy";
import { createRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../../images/final_logo.webp";
import {
  header,
  menuIcon,
  menuOptionForMoreInHeader,
} from "../../../constants";
import LoggedInMenuNew from "./LoggedInMenuNew";
import Image from "../../Image";

const DesktopHeader = () => {
  const navigate = useNavigate();
  const ref = createRef();
  const user = JSON.parse(localStorage.getItem("mop-user"));
  const setMoreMenu = useStoreActions((actions) => actions.setMoreMenu);
  const setProviderMenu = useStoreActions((actions) => actions.setProviderMenu);
  const moreMenu = useStoreState((state) => state.moreMenu);
  //logged in user details.
  const getLoggedinUsers = useStoreActions(
    (actions) => actions.getLoggedinUsers
  );
  const getLoggedinPatient = useStoreActions(
    (actions) => actions.getLoggedinPatient
  );

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    if (user)
      if (user?.role === "patient") getLoggedinPatient(user?.id);
      else getLoggedinUsers(user?.id);
    // eslint-disable-next-line
  }, [localStorage.getItem("mop-user")]);

  const closeAll = () => {
    setMoreMenu(false);
    setProviderMenu(false);
  };
  return (
    <>
      <NavLink to="/" className="navbar-brand mx-auto">
        <Image
          local={true}
          src={Logo}
          alt="My Orthopedic Problem"
          onClick={closeAll}
          className="width-8rem"
        />
      </NavLink>
      <div
        ref={ref}
        className="collapse navbar-collapse"
        id="navbarTogglerDemo03"
      >
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          {header?.map((headers) => (
            <li className="nav-item">
              <NavLink
                to={`/${headers?.to}`}
                className={`nav-link fw-semibold ${
                  headers.text === "Symptom Checker" ? "active" : ""
                }`}
              >
                {headers?.text}
              </NavLink>
            </li>
          ))}
          <li className="nav-item dropdown">
            <div
              className={`nav-link fw-semibold dropdown-toggle navigation-web ${
                window.location.pathname.includes("press")
                  ? "active"
                  : window.location.pathname.includes("about")
                  ? "active"
                  : window.location.pathname.includes("suppliers")
                  ? "active"
                  : window.location.pathname.includes("pharmaceutical")
                  ? "active"
                  : ""
              }`}
              role="button"
              id="navbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={() => {
                setProviderMenu(false);
                setMoreMenu(true);
              }}
            >
              More
              {menuIcon()}
            </div>
            <ul
              className="dropdown-menu mt-2"
              style={{ visibility: moreMenu ? "visible" : "hidden" }}
              aria-labelledby="navbarDropdown"
            >
              {menuOptionForMoreInHeader.map((item, index) => (
                <li key={index}>
                  <div
                    key={index}
                    onClick={() => {
                      navigate(`/${item.path}`);
                      setMoreMenu(false);
                    }}
                    className={`tab-menu  cursor-pointer ${
                      window.location.pathname.includes(item.path)
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    {item.name}
                  </div>
                </li>
              ))}
            </ul>
          </li>
        </ul>
        {user ? (
          <div id="home-btn">
            <LoggedInMenuNew />
          </div>
        ) : (
          <div className="d-flex" id="home-btn">
            <button
              className="btn btn-outline-primary px-4 rounded-pill fw-semibold me-3"
              type="button"
              onClick={() => {
                navigate("/login");
                closeAll();
              }}
            >
              Login
            </button>
            <button
              className="btn btn-primary rounded-pill px-4 fw-semibold"
              type="button"
              onClick={() => {
                navigate("/usertype", { state: "signup" });
                closeAll();
              }}
            >
              Sign Up
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default DesktopHeader;
