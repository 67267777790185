import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../../images/final_logo.webp";
import {
  header,
  menuIcon,
  menuOptionForMoreInHeader,
} from "../../../constants";
import { activePath } from "../../../util/utils";
import LoggedInMenuNew from "./LoggedInMenuNew";
import LoggedInSideBar from "./LoggedInSideBar";
import Image from "../../Image";

const MobileHeader = () => {
  //Constant and variables
  const navigate = useNavigate();
  const [select, setSelect] = useState(false);
  const [show, setShow] = useState(false);
  const user = JSON.parse(localStorage.getItem("mop-user"));
  const setMoreMenu = useStoreActions((actions) => actions.setMoreMenu);
  const setProviderMenu = useStoreActions((actions) => actions.setProviderMenu);
  const moreMenu = useStoreState((state) => state.moreMenu);
  //logged in user details.
  const getLoggedinUsers = useStoreActions(
    (actions) => actions.getLoggedinUsers
  );
  const getLoggedinPatient = useStoreActions(
    (actions) => actions.getLoggedinPatient
  );
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  useEffect(() => {
    if (user)
      try {
        if (user?.role === "patient") getLoggedinPatient(user?.id);
        else getLoggedinUsers(user?.id);
      } catch (error) {}
    // eslint-disable-next-line
  }, [localStorage.getItem("mop-user")]);

  return (
    <>
      <button className="navbar-toggler p-0" type="button" onClick={handleOpen}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="#0c4d9d"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 8h16M4 16h16"
          />
        </svg>
      </button>
      <div
        className={`offcanvas offcanvas-start d-lg-none d-block${
          show ? " show visibility-visible" : "visibility-hidden"
        }`}
        tabIndex="-1"
        id="offcanvasWithBothOptions"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
            <NavLink to="/" className="navbar-brand mx-auto">
              <Image
                local={true}
                src={Logo}
                className="w-50 h-50"
                alt="My orthopedic Problem"
                onClick={handleClose}
              />
            </NavLink>
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              handleClose();
              setSelect(false);
            }}
          />
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {header?.map((headers) => (
              <li className="nav-item">
                <NavLink
                  to={`/${headers?.to}`}
                  className="nav-link fw-semibold"
                  onClick={handleClose}
                >
                  {headers?.text}
                </NavLink>
              </li>
            ))}
            <li className="nav-item dropdown">
              <span
                className={`nav-link fw-semibold dropdown-toggle navigation-web ${activePath()}`}
                role="button"
                onClick={() => {
                  setProviderMenu(false);
                  setMoreMenu(!moreMenu);
                }}
              >
                More
                {menuIcon()}
              </span>
              <ul
                className={`dropdown-menu mt-2 ${
                  moreMenu ? "d-block" : "d-none"
                }`}
                aria-labelledby="navbarDropdown"
              >
                {menuOptionForMoreInHeader.map((item, index) => (
                  <li key={index}>
                    <div
                      onClick={() => {
                        handleClose();
                        navigate(`/${item.path}`);
                        setMoreMenu(false);
                      }}
                      className={`tab-menu cursor-pointer ${
                        window.location.pathname.includes(item.path)
                          ? "active-tab"
                          : ""
                      }`}
                    >
                      {item.name}
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
          {user ? (
            <div className="d-sm-grid px-3" id="home-btn">
              <LoggedInMenuNew />
              <hr />
              <LoggedInSideBar
                setSelect={setSelect}
                handleClose={handleClose}
                select={select}
              />
            </div>
          ) : (
            <div className="d-sm-grid" id="home-btn">
              <button
                className="btn btn-outline-primary px-4 rounded-pill fw-bold me-3 mb-lg-0 mb-3"
                type="button"
                onClick={() => {
                  handleClose();
                  navigate("/login");
                }}
              >
                Login
              </button>
              <button
                className="btn btn-primary rounded-pill px-4 fw-bold text-nowrap me-3 mb-lg-0 mb-3"
                type="button"
                onClick={() => {
                  handleClose();
                  navigate("/usertype", { state: "signup" });
                }}
              >
                Sign Up
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
