import { action, thunk } from "easy-peasy";
import axios from "../util/axiosInst";

const model = {
  providerDashboardData: {},
  patientDashboardData: {},
  chartData: {},
  patientchartData: {},
  providerId: null,
  provider: {},

  setpatientDashboardData: action((state, payload) => {
    state.patientDashboardData = payload;
  }),

  setProviders: action((state, payload) => {
    state.providerId = payload;
  }),

  setDashboardProvider: action((state, payload) => {
    state.provider = payload;
  }),

  setProviderDashboardData: action((state, payload) => {
    state.providerDashboardData = payload;
  }),

  setChartData: action((state, payload) => {
    state.chartData = payload;
  }),
  setPatientChartData: action((state, payload) => {
    state.patientchartData = payload;
  }),

  getDashboardAnalytics: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    try {
      const { data: providerData } = await axios.get(
        `/v1/public/provider/${payload}`
      );

      actions.setProviders(providerData.id || providerData._id);
      actions.setDashboardProvider(providerData);
      localStorage.setItem("providerId", state.providerId);

      const { data } = await axios.get(
        `/v1/provider/analytics/${providerData.id || providerData._id}`
      );

      actions.setProviderDashboardData(data);
    } catch (error) {}
  }),

  getPatientDashboardData: thunk(async (actions, payload, { getState }) => {
    try {
      const { data } = await axios.get(`/v1/patient/analytics`);
      actions.setpatientDashboardData(data);
    } catch (error) {}
  }),

  postPatientData: thunk(async (actions, payload, { getState }) => {
    try {
      await axios.post(`/v1/patient/analytics`, {
        type: payload.type,
        patientId: payload.patientId,
        email: payload.email,
      });
    } catch (error) {}
  }),

  getAnalyticsChartData: thunk(async (actions, payload, { getState }) => {
    const state = getState();

    try {
      let tempId = null;
      if (!state.providerId) {
        const mopUser = JSON.parse(localStorage.getItem("mop-user"));

        const { data: providerData } = await axios.get(
          `/v1/public/provider/${mopUser?.id}`
        );
        tempId = providerData.id || providerData._id;
        actions.setProviders(providerData.id || providerData._id);
        actions.setDashboardProvider(providerData);
      }
      const { data } = await axios.get(`/v1/provider/analytics/chart`, {
        params: {
          id: state.providerId ? state.providerId : tempId,
          type: payload.type,
          range: payload.range,
        },
      });

      let tempState = JSON.parse(JSON.stringify(state.chartData));
      if (!tempState[payload.type]) {
        tempState[payload.type] = {};
      }
      if (!tempState[payload.type][payload.range]) {
        tempState[payload.type][payload.range] = {};
      }
      tempState[payload.type][payload.range] = data;

      actions.setChartData(tempState);
    } catch (error) {}
  }),
  getAnalyticsChartDataPatient: thunk(
    async (actions, payload, { getState }) => {
      const state = getState();

      try {
        let tempId = null;
        if (!state.providerId) {
          const mopUser = JSON.parse(localStorage.getItem("mop-user"));

          const { data: providerData } = await axios.get(
            `v1/patient/-/${mopUser?.id}`
          );
          tempId = providerData.id || providerData._id;
          // actions.setProviders(providerData.id || providerData._id);
          // actions.setDashboardProvider(providerData);
        }
        const { data } = await axios.get(
          `/v1/patient/analytics/chart?id=${tempId}&type=${payload.type}&range=${payload.range}`
        );

        let tempState = JSON.parse(JSON.stringify(state.chartData));
        if (!tempState[payload.type]) {
          tempState[payload.type] = {};
        }
        if (!tempState[payload.type][payload.range]) {
          tempState[payload.type][payload.range] = {};
        }
        tempState[payload.type][payload.range] = data;

        actions.setPatientChartData(tempState);
      } catch (error) {}
    }
  ),
};

export default model;
