import moment from "moment";
import { isUndefined } from "lodash";

export const muscleGroupConstant = [
  "knee",
  "shoulder",
  "hand_wrist",
  "foot_ankle",
  "back",
  "neck",
  "hip",
  "elbow",
];

export const getProviderAddress = (provider) => {
  if (provider?.clinic.length > 0) {
    if (
      provider?.clinic[0]?.addressLine === "" &&
      provider?.clinic[0]?.city === "" &&
      provider?.clinic[0]?.state === "" &&
      provider?.clinic[0]?.zipcode === ""
      // && provider?.clinic[0]?.country === ""
    ) {
      return "";
    } else if (
      provider?.clinic[0]?.addressLine === undefined &&
      provider?.clinic[0]?.city === undefined &&
      provider?.clinic[0]?.state === undefined &&
      provider?.clinic[0]?.zipcode === undefined
      // && provider?.clinic[0]?.country === undefined
    ) {
      return "";
    } else {
      return (
        <p>
          {provider?.clinic[0]?.addressLine && provider?.clinic[0]?.addressLine}
          <br />
          {provider?.clinic[0]?.city && provider?.clinic[0]?.city + ","}{" "}
          {provider?.clinic[0]?.state && provider?.clinic[0]?.state + ","}{" "}
          {provider?.clinic[0]?.zipcode && provider?.clinic[0]?.zipcode}
        </p>
      );
    }
  } else {
    if (
      provider?.clinic?.addressLine === "" &&
      provider?.clinic?.city === "" &&
      provider?.clinic?.state === "" &&
      provider?.clinic?.zipcode === ""
      // && provider?.clinic?.country === ""
    )
      return "";
    else
      return (
        <p>
          {provider?.clinic?.addressLine && provider?.clinic?.addressLine}{" "}
          <br />
          {provider?.clinic?.city && provider?.clinic?.city + ","}{" "}
          {provider?.clinic?.state && provider?.clinic?.state + ","}{" "}
          {provider?.clinic?.zipcode && provider?.clinic?.zipcode}
        </p>
      );
  }
};

export const gymAddress = (providers) => {
  if (
    providers?.userType === "rehabilitation_center" ||
    providers?.userType === "gym" ||
    providers?.userType === "hospital" ||
    providers?.userType === "clinic"
  ) {
    if (
      providers?.address === undefined &&
      providers?.city === undefined &&
      providers?.state === undefined &&
      providers?.zipcode === undefined
    )
      return "";
    else if (
      providers?.address === "" &&
      providers?.city === "" &&
      providers?.state === "" &&
      providers?.zipcode === ""
    )
      return "";
    else {
      return (
        <>
          {providers?.address && providers?.address} <br />
          {providers?.city && providers?.city + ","}{" "}
          {providers?.state && providers?.state + ","}{" "}
          {providers?.zipcode && providers?.zipcode}
        </>
      );
    }
  } else {
    if (
      providers?.address === undefined &&
      providers?.city === undefined &&
      providers?.state === undefined &&
      providers?.zipcode === undefined
      // && providers?.country === undefined
    ) {
      return "";
    } else if (
      providers?.address === "" &&
      providers?.city === "" &&
      providers?.state === "" &&
      providers?.zipcode === ""
      // && providers?.country === undefined
    )
      return "";
    else if (
      providers?.address === "" &&
      providers?.city === "" &&
      providers?.state === "" &&
      // providers?.country === "" &&
      providers?.zipcode === ""
    )
      return "";
    else
      return (
        <>
          {providers?.address && providers?.address} <br />
          {providers?.city && providers?.city + ","}{" "}
          {providers?.state && providers?.state + ","}{" "}
          {providers?.zipcode && providers?.zipcode}
        </>
      );
  }
};

export const getProviderName = (provider) => {
  return (
    <span>
      {provider.prefix &&
      provider.prefix.length &&
      provider.prefix.includes(".")
        ? provider.prefix
          ? provider.prefix
          : ""
        : provider.prefix
        ? provider.prefix + ". "
        : ""}
      {provider.firstName &&
        provider.firstName.length &&
        provider.firstName + " "}
      {provider.lastName && provider.lastName.length && provider.lastName}
      {provider.suffix && provider.suffix.length && " , " + provider.suffix}
    </span>
  );
};

export const getName = (provider) => {
  return isUndefined(provider.prefix)
    ? isUndefined(provider.suffix)
      ? provider?.firstName + " " + provider?.lastName
      : provider?.suffix === ""
      ? provider?.firstName + " " + provider?.lastName
      : provider?.firstName +
        " " +
        provider?.lastName +
        " , " +
        provider?.suffix
    : isUndefined(provider.suffix)
    ? provider?.prefix + ". " + provider?.firstName + " " + provider?.lastName
    : provider?.suffix === ""
    ? provider?.prefix + ". " + provider?.firstName + " " + provider?.lastName
    : provider?.prefix +
      ". " +
      provider?.firstName +
      " " +
      provider?.lastName +
      " , " +
      provider?.suffix;
};
export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "1-" + match[1] + "-" + match[2] + "-" + match[3];
  }
  return phoneNumber;
};

export const formatPhoneNumbers = (phoneNumber) => {
  var cleaned = ("" + phoneNumber).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, `(${match[2]})`, "-", match[3], "-", match[4]].join("");
  }
};
export const timevalue = [
  "12:00 AM",
  "12:15 AM",
  "12:30 AM",
  "12:45 AM",
  "01:00 AM",
  "01:15 AM",
  "01:30 AM",
  "01:45 AM",
  "02:00 AM",
  "02:15 AM",
  "02:30 AM",
  "02:45 AM",
  "03:00 AM",
  "03:15 AM",
  "03:30 AM",
  "03:45 AM",
  "04:00 AM",
  "04:15 AM",
  "04:30 AM",
  "04:45 AM",
  "05:00 AM",
  "05:15 AM",
  "05:30 AM",
  "05:45 AM",
  "06:00 AM",
  "06:15 AM",
  "06:30 AM",
  "06:45 AM",
  "07:00 AM",
  "07:15 AM",
  "07:30 AM",
  "07:45 AM",
  "08:00 AM",
  "08:15 AM",
  "08:30 AM",
  "08:45 AM",
  "09:00 AM",
  "09:15 AM",
  "09:30 AM",
  "09:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "01:00 PM",
  "01:15 PM",
  "01:30 PM",
  "01:45 PM",
  "02:00 PM",
  "02:15 PM",
  "02:30 PM",
  "02:45 PM",
  "03:00 PM",
  "03:15 PM",
  "03:30 PM",
  "03:45 PM",
  "04:00 PM",
  "04:15 PM",
  "04:30 PM",
  "04:45 PM",
  "05:00 PM",
  "05:15 PM",
  "05:30 PM",
  "05:45 PM",
  "06:00 PM",
  "06:15 PM",
  "06:30 PM",
  "06:45 PM",
  "07:00 PM",
  "07:15 PM",
  "07:30 PM",
  "07:45 PM",
  "08:00 PM",
  "08:15 PM",
  "08:30 PM",
  "08:45 PM",
  "09:00 PM",
  "09:15 PM",
  "09:30 PM",
  "09:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM",
];

export const futureDatesDisable = (current) => {
  const today = moment();
  return current.isBefore(today);
};

export const pastDatesDisable = (current) => {
  const today = moment().subtract(1, "day");
  return current.isAfter(today);
};

export const EmptySplice = (InitValue, values) => {
  values?.length &&
    values.map((map, ind) => {
      if (JSON.stringify(InitValue) === JSON.stringify(map)) {
        values.splice(ind, 1);
      }
      return null;
    });
};

export const getEndTime = (ind, index, watchStartTime, setValue) => {
  let tempArray = [...timevalue];
  if (watchStartTime) {
    const lastIndex = tempArray.findIndex(
      (time) => time === watchStartTime[ind]?.timings[index]?.start_time
    );
    let itemToBeRemoved = tempArray.splice(0, lastIndex + 1);
    if (
      itemToBeRemoved.includes(watchStartTime[ind]?.timings[index]?.end_time)
    ) {
      setValue(`classes_info[${ind}].timings[${index}].end_time`, "");
    }
    return [...itemToBeRemoved];
  } else {
    return null;
  }
};

export const photoCondition = (photo) => {
  if (
    photo?.includes("Female-Dr-Avatar") ||
    photo?.includes("Male-Dr-Avatar") ||
    photo?.includes("dummyuserProfilemop")
  ) {
    photo = "";
  }
};

//return the url according to usertype
export const renderLink = (provider) => {
  if (provider?.userType === "personal_trainer")
    return "/personal-trainer-profile";
  else if (provider?.userType === "hospital") return "/hospital-profile";
  else if (provider?.userType === "clinic") return "/clinic-profile";
  else if (provider?.userType === "rehabilitation_center")
    return "/rehabilitation-center-profile";
  else if (provider?.userType === "gym") return "/gym-profile";
  else if (provider?.userType === "athletic_trainers")
    return "/athletic-trainers-profile";
  else if (provider?.userType === "wellness_provider")
    return "/wellness-provider-profile";
  else if (provider?.userType === "physical_therapist")
    return "/physical-therapist-profile";
  else if (provider?.userType === "chiropractor") return "/Chiropractor";
  else if (provider?.userType === "pain_management_specialist")
    return "/pain-management-specialist";
  else if (provider?.userType === "massage_therapist")
    return "/massage-therapist";
  else return "/provider-profile";
};

//for auto logout the user.
export const logOutUser = async () => {
  localStorage.removeItem("mop-token");
  localStorage.removeItem("mop-user");
  localStorage.removeItem("claim-id");
  localStorage.removeItem("claim-status");
  sessionStorage.removeItem("membershipDetails");
  window.location.href = "/login";
};

export const loggedInUserNameWithSplit = (provider, user) => {
  return provider
    ? provider.userType === "hospital" ||
      provider.userType === "clinic" ||
      provider.userType === "rehabilitation_center" ||
      provider.userType === "gym"
      ? provider?.firstName.split(" ", 2).join(" ")
      : (provider?.firstName + " " + provider?.lastName).split(" ", 2).join(" ")
    : user?.name.split(" ", 2).join(" ");
};

export const loggedInUserNameWithoutSplit = (provider, user) => {
  return provider
    ? provider.userType === "hospital" ||
      provider.userType === "clinic" ||
      provider.userType === "rehabilitation_center" ||
      provider.userType === "gym"
      ? provider?.firstName
      : provider?.firstName + " " + provider?.lastName
    : user?.name;
};

//check any child menu is opened then show parent menu as active.
export const activePath = () => {
  if (window.location.pathname.includes("press")) {
    return "active";
  } else if (window.location.pathname.includes("about")) {
    return "active";
  } else if (window.location.pathname.includes("mission-statement")) {
    return "active";
  } else if (window.location.pathname.includes("suppliers")) {
    return "active";
  } else if (window.location.pathname.includes("pharmaceutical")) {
    return "active";
  } else {
    return "";
  }
};

export const handleClose = (setClose) => {
  setClose(false);
};

export const NavigateFunction = (
  userType,
  apiResponse,
  navigate,
  userplan,
  path
) => {
  navigate(path, {
    state: {
      userType: userType,
      membershipID: userplan[0]?.id,
      apiResponse: apiResponse,
    },
  });
};

export const setTimer = (setShow) => {
  const interval = setInterval(() => {
    setShow(true);
  }, 10000);
  return () => clearInterval(interval);
};

export const getDateFormat = (date) => {
  const todayDate = new Date();
  const days_since_commented = Math.round(
    (todayDate - new Date(date)) / 86400000
  );
  var days_ago_text;
  switch (days_since_commented) {
    case 0:
      days_ago_text = "Today";
      break;
    case 1:
      days_ago_text = "1 day ago";
      break;
    default:
      days_ago_text = `${days_since_commented} days ago`;
  }
  return days_ago_text;
};

export const NavigateProviderRegisterFunction = (
  userType,
  apiResponse,
  navigate,
  userplan,
  path
) => {
  navigate(path, {
    state: {
      userType: userType,
      userPlan: userplan,
      apiResponse: apiResponse,
    },
  });
};
