import { Offcanvas } from "react-bootstrap";
import LoggedInSideBar from "./LoggedInSideBar";
import Logo from "../../../images/final_logo.webp";
import { useState } from "react";
import Image from "../../Image";

const OffCanvasExample = ({ name, ...props }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [select, setSelect] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          handleShow();
          setSelect(false);
        }}
        className="bg-transparent border-0 h-25 w-25 h1 mt-3 pt-1 d-md-flex d-none"
      >
        <i className="fa-solid fa-bars fa-xs fs--25"></i>
      </button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton className="p-0  w-100">
          <Offcanvas.Title className="w-100">
            {" "}
            <div className="row">
              <div
                className={`${
                  window.innerWidth > 1300
                    ? "col-sm-6"
                    : window.innerWidth > 1200
                    ? "col-sm-5"
                    : "col-sm-4"
                } logo-drawer col-5 cursor-pointer`}
              >
                <Image
                  local={true}
                  src={Logo}
                  className="w-100 logo-responsive ms-3"
                  alt="headerLogo"
                />
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="border border-primary opacity-100 my-2"></hr>
        <Offcanvas.Body>
          <LoggedInSideBar
            setSelect={setSelect}
            select={select}
            lineHeight={"4rem"}
            handleClose={handleClose}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OffCanvasExample;
