import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import DesktopHeader from "./Header/DesktopHeader";
import MobileHeader from "./Header/MobileHeader";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.pathname.endsWith("/"))
      navigate(location?.pathname.slice(0, -1));
    else if (location?.pathname === "/blog") navigate("/provider-posts");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return (
    <header>
      <nav className="navbar navbar-expand-lg bg-white">
        <div className="container-fluid px-lg-4 px-3 justify-content-lg-between justify-content-start">
          {window.innerWidth < 740 ? <MobileHeader /> : <DesktopHeader />}
        </div>
      </nav>
    </header>
  );
};

export default Header;
