import HipImage from "../images/evaluated/mop_hip.webp";
import KneeImage from "../images/evaluated/mop_knee.webp";
import ShoulderImage from "../images/evaluated/mop_shoulder.webp";
import HandImage from "../images/evaluated/mop_hand.webp";
import FootImage from "../images/evaluated/mop_foot.webp";
import Back from "../images/evaluated/mop_back.webp";
import Neck from "../images/evaluated/mop_neck.webp";
import Elbow from "../images/evaluated/mop_elbow.webp";

export const alertMessages =
  "Thank you for visiting MyOrthopedicProblem.com. Please click ok below to proceed to finding a provider of your choice. You may also choose to continue reading education content before selecting a provider.";
export const user = "info";
export const domain = "myorthopedicproblem.com";

export const profileUpdate = "Profile updated successfully.";

export const Days = [
  {
    value: "Sunday",
  },
  {
    value: "Monday",
  },
  {
    value: "Tuesday",
  },
  {
    value: "Wednesday",
  },
  {
    value: "Thursday",
  },
  {
    value: "Friday",
  },
  {
    value: "Saturday",
  },
];

export const fmsUrl =
  (!!process.env.REACT_APP_SERVER_URL &&
    process.env.REACT_APP_SERVER_URL.replace(/\/$/, "")) + "/v1/fms/";

export const ProvidersList = [
  { value: "nurse_practitioner", label: "Nurse Practitioner" },
  { value: "orthopedic_surgeon", label: "Orthopedic Surgeon" },
  { value: "chiropractor", label: "Chiropractor" },
  { value: "pain_management_specialist", label: "Pain Management Specialist" },
  { value: "massage_therapist", label: "Massage Therapist" },
  { value: "neuro_surgeon", label: "Neurosurgeon" },
  { value: "primary_care", label: "Primary Care" },
  { value: "physical_therapist", label: "Physical Therapist" },
  { value: "wellness_provider", label: "Wellness Provider" },
  { value: "personal_trainer", label: "Personal Trainer" },
  { value: "hospital", label: "Hospital" },
  { value: "clinic", label: "Clinic" },
  { value: "rehabilitation_center", label: "Rehabilitation Center" },
  { value: "athletic_trainers", label: "Athletic Trainers" },
  { value: "gym", label: "Gym" },
];
export const ProviderFAQ = [
  {
    Question: "How can MyOrthopedicProblem.com help grow my practice?",
    Answer: `By being listed on our platform, you gain visibility among users
    seeking orthopedic care in your area. Our website connects potential
    patients with local providers, making it easier for them to find and
    choose your practice.`,
  },
  {
    Question: "How much does it cost to create a provider profile?",
    Answer: `There is no initial fee for basic listings on our platform. However,
    we do offer premium subscription options for enhanced visibility and
    additional features, such as customizable profiles and appointment
    scheduling.`,
  },
  {
    Question: "What are the benefits of creating a profile?",
    Answer: `By creating a provider profile, you'll receive a directory listing,
    profile customization options, ratings and reviews, appointment
    scheduling features, and the opportunity to contribute educational
    content. These features help increase your online visibility,
    showcase your expertise, and attract potential patients.`,
  },
  {
    Question: "How do I Sign Up?",
    Answer: ` To Sign Up, Click the Sign Up button input your Provider type and
    complete the registration form. Our team will review your submission
    and, if approved, add you to our directory.`,
  },
  {
    Question: "How do I claim my profile?",
    Answer: ` To claim your profile, simply click “Claim My Profile” on your page
  listing and complete the registration form.`,
  },
];
export const individualsFAQ = [
  {
    Question: "What is MyOrthopedicProblem.com?",
    Answer: `MyOrthopedicProblem.com is an online platform that offers
    preliminary diagnoses using our symptom checker, industry leading
    educational resources, and connections to local healthcare providers
    for individuals with orthopedic conditions. Our goal is to help
    users better understand their issues, become informed about
    treatments, and access quality care with ease.`,
  },
  {
    Question: "How much does it cost to use?",
    Answer: `MyOrthopedicProblem.com is completely free for users. We aim to
  provide accessible and reliable information for everyone seeking
  orthopedic care.`,
  },
  {
    Question: "Where is the best place for me to begin?",
    Answer: ` Get evaluated by using our symptom checker. This will provide a
  preliminary diagnosis based on the information provided. Remember,
  this is not a substitute for professional medical advice.`,
  },
  {
    Question: "How do I learn more about my injury?",
    Answer: `Our industry leading educational resources are created and regularly
  updated by medical professionals. However, they are for
  informational purposes only and should not replace personalized
  advice from a healthcare professional.`,
  },
  {
    Question: "How do I get treatment for my orthopedic injury?",
    Answer: `Use the "Find a Provider" feature on our website. Enter your
  location and preferred specialty to receive a list of qualified
  orthopedic specialists nearby to schedule an appointment, along with
  ratings, reviews, and contact information.`,
  },
];

export const header = [
  { text: "Symptom Checker", to: "evaluation" },
  { text: "Education Library", to: "education" },
  { text: "Find a Provider", to: "providers" },
  { text: "Exercises", to: "exercises" },
];

export const SocialmediaProfile = [
  { id: "facebook", Tooltip: "Facebook URL", label: "Facebook" },
  { id: "twitter", Tooltip: "Twitter URL", label: "Twitter" },
  { id: "instagram", Tooltip: "Instagram URL", label: "Instagram" },
  { id: "youtube", Tooltip: "Youtube URL", label: "Youtube" },
  { id: "linkedin", Tooltip: "LinkedIn URL", label: "LinkedIn" },
];
export const EmailProfile = [
  {
    id: "infoEmail1",
    Tooltip:
      "Email addresses below are for receiving correspondence from MyOrthopedicProblem.com. Please add team member’s email addresses who are responsible for fielding these request",
    label: "Email ID 1",
    name: "emailIds[0]",
  },
  {
    id: "infoEmail2",
    Tooltip:
      "Email addresses below are for receiving correspondence from MyOrthopedicProblem.com. Please add team member’s email addresses who are responsible for fielding these request",
    label: "Email ID 2",
    name: "emailIds[1]",
  },
  {
    id: "infoEmail3",
    Tooltip:
      "Email addresses below are for receiving correspondence from MyOrthopedicProblem.com. Please add team member’s email addresses who are responsible for fielding these request",
    label: "Email ID 3",
    name: "emailIds[2]",
  },
  {
    id: "infoEmail4",
    Tooltip:
      "Email addresses below are for receiving correspondence from MyOrthopedicProblem.com. Please add team member’s email addresses who are responsible for fielding these request",
    label: "Email ID 4",
    name: "emailIds[3]",
  },
];

export const websiteRegex =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
export const FBurl = /^(https?:\/\/)?(www\.)?facebook\.com\/.*/;
export const TWurl = /^(https?:\/\/)?(www\.)?twitter\.com\/.*/;
export const insUrl = /^(https?:\/\/)?(www\.)?instagram\.com\/.*/;
export const youtubeUrl = /^(https?:\/\/)?(www\.)?youtube\.com\/.*/;
export const linkedinUrl =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9_-]+\/?/;
export const EmailRequired = "Email is a required field";
export const EmailValid = "Email must be a valid email";
export const bookAnAppointmentText =
  "Your selected provider is not receiving online appointment request at this time. Please contact your selected provider at ";

export const NoDataFound = "No Data Found";

export const alertMessage =
  "Please do take sometime and complete your profile. This info would be much helpful for the patients/users on the portal to reach you out on the MOP portal. Thanks";

//options for more menu in header.
export const menuOptionForMoreInHeader = [
  { name: "Newsroom", path: "press" },
  { name: "About", path: "about" },
  // { name: "Mission Statement", path: "mission-statement" },
  { name: "Supplies", path: "suppliers" },
  { name: "Pharmaceutical", path: "pharmaceutical" },
];

export const menuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      fill="currentColor"
      className="fas fa-chevron-down menu-icon"
      viewBox="0 0 16 16"
      strokeWidth="2"
      stroke="#000000"
    >
      <path
        fillRule="evenodd"
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};

//all the user types with their message and path
export const userType = [
  {
    value: "patient",
    label: "Individual",
    path: "/signup",
    message:
      "Save your evaluation data, educate yourself on your conditions, engage with providers and more",
    image: "patients.svg",
  },
  {
    value: "orthopedic_surgeon",
    label: "Orthopedic Surgeon",
    path: "/pricing/orthopedic_surgeon",
    message:
      "Quickly and easily educate patient on MSK issues while creating a network of trusted providers",
    image: "mop orthopedic surgeon.svg",
  },

  {
    value: "nurse_practitioner",
    label: "Nurse Practitioner",
    path: "/pricing/nurse_practitioner",
    message:
      "Easily Educate your patients on Musculoskeletal Issues leading to better comprehension and outcomes",
    image: "mop nurse.svg",
  },
  {
    value: "neuro_surgeon",
    label: "Neurosurgeon",
    path: "/pricing/neuro_surgeon",
    message:
      "Quickly and easily educate patient on MSK issues while creating a network of trusted providers",
    image: "mop neurosurgeon.svg",
  },
  {
    value: "primary_care",
    label: "Primary Care",
    path: "/pricing/primary_care",
    message:
      "Quickly and easily educate patient on MSK issues while creating a network of trusted providers",
    image: "mop primary.svg",
  },
  {
    value: "physical_therapist",
    label: "Physical Therapist",
    path: "/pricing/physical_therapist",
    message:
      "Create a network of trusted providers and a library of exercises for you and your clients",
    image: "mop physical therapist.svg",
  },
  {
    value: "wellness_provider",
    label: "Wellness Provider",
    path: "/pricing/wellness_provider",
    message: "Create a network of trusted providers for you and your clients",
    image: "Wellness Provider.svg",
  },
  {
    value: "personal_trainer",
    label: "Personal Trainer",
    path: "/pricing/personal_trainer",
    message:
      "Create a network of trusted providers and a library of exercises for you and your clients",
    image: "mop personal trainer.svg",
  },
  {
    value: "athletic_trainers",
    label: "Athletic Trainers",
    path: "/pricing/athletic_trainers",
    message:
      "Easily Educate your patients on Musculoskeletal Issues leading to better comprehension and outcomes",
    image: "Athletic Trainer.svg",
  },
  {
    value: "chiropractor",
    label: "Chiropractor",
    path: "/pricing/chiropractor",
    message:
      "Create a network of trusted providers and a library of exercises for you and your clients",
    image: "mop chiro.svg",
  },
  {
    value: "pain_management_specialist",
    label: "Pain Management Specialist",
    path: "/pricing/pain_management_specialist",
    message:
      "Easily educate your patients on musculoskeletal issues leading to better comprehension and outcomes",
    image: "PainM.svg",
  },
  {
    value: "massage_therapist",
    label: "Massage Therapist",
    path: "/pricing/massage_therapist",
    message: "Create a network of trusted providers to generate more referrals",
    image: "Massage Therapist.svg",
  },
  {
    value: "rehabilitation_center",
    label: "Rehabilitation Center",
    path: "/pricing/rehabilitation_center",
    message:
      "Easily Educate your patients on MSK Issues leading to better comprehension and outcomes",
    image: "RehabCenter.svg",
  },
  {
    value: "gym",
    label: "Gym",
    path: "/pricing/gym",
    message:
      "Tools to help staff assist members with their health and wellness goals. Create a trusted network of Providers.",
    image: "mop gym.svg",
  },
  {
    value: "hospital",
    label: "Hospital",
    path: "/pricing/hospital",
    message:
      "Increased patient satisfaction, less patient leakage and better outcomes.",
    image: "Hospital.svg",
  },
  {
    value: "clinic",
    label: "Clinic",
    path: "/pricing/clinic",
    message:
      "Easily Educate your patients on MSK Issues leading to better comprehension and outcomes",
    image: "Clinic.svg",
  },
];

//all the provider types with their message for provider types.
export const providerTypes = [
  {
    value: "orthopedic_surgeon",
    label: "Orthopedic Surgeon",
    message: "Click here to see available Orthopedic Surgeons in your area",
    image: "mop orthopedic surgeon.svg",
  },
  {
    value: "nurse_practitioner",
    label: "Nurse Practitioner",
    message: "Click here to see available Nurse Practitioners in your area",
    image: "mop nurse.svg",
  },
  {
    value: "primary_care",
    label: "Primary Care",
    message: "Click here to see available Primary Care Physicians in your area",
    image: "mop primary.svg",
  },
  {
    value: "neuro_surgeon",
    label: "Neurosurgeon",
    message: "Click here to see available Neurosurgeons in your area",
    image: "mop neurosurgeon.svg",
  },
  {
    value: "physical_therapist",
    label: "Physical Therapist",
    message: "Click here to see available Physical Therapist in your area",
    image: "mop physical therapist.svg",
  },
  {
    value: "wellness_provider",
    label: "Wellness Provider",
    message:
      "Click here to see the large variety of Wellness Providers in your area",
    image: "Wellness Provider.svg",
  },
  {
    value: "personal_trainer",
    label: "Personal Trainer",
    message: "Click here to see available Personal Trainers in your area",
    image: "mop personal trainer.svg",
  },
  {
    value: "athletic_trainers",
    label: "Athletic Trainers",
    message: "Click here to see available Athletic Trainers in your area",
    image: "Athletic Trainer.svg",
  },
  {
    value: "chiropractor",
    label: "Chiropractor",
    message:
      "Create a network of trusted providers and a library of exercises for you and your clients",
    image: "mop chiro.svg",
  },
  {
    value: "pain_management_specialist",
    label: "Pain Management Specialist",
    message:
      "Easily educate your patients on musculoskeletal issues leading to better comprehension and outcomes",
    image: "PainM.svg",
  },
  {
    value: "massage_therapist",
    label: "Massage Therapist",
    message: "Create a network of trusted providers to generate more referrals",
    image: "Massage Therapist.svg",
  },
  {
    value: "rehabilitation_center",
    label: "Rehabilitation Center",
    message: "Click here to see available Rehabilitation Centers in your area",
    image: "RehabCenter.svg",
  },
  {
    value: "gym",
    label: "Gym",
    message: "Click here to see available Gyms in your area",
    image: "mop gym.svg",
  },
  {
    value: "hospital",
    label: "Hospital",
    message: "Click here to see available Hospitals in your area",
    image: "Hospital.svg",
  },
  {
    value: "clinic",
    label: "Clinic",
    message: "Click here to see available Clinic in your area",
    image: "Clinic.svg",
  },
];

export const MuscleTypes = [
  { name: "Knee", image: KneeImage, slug: "knee" },
  { name: "Shoulder", image: ShoulderImage, slug: "shoulder" },
  { name: "Hand & Wrist", image: HandImage, slug: "hand_wrist" },
  { name: "Foot & Ankle", image: FootImage, slug: "foot_ankle" },
  { name: "Back", image: Back, slug: "back" },
  { name: "Neck", image: Neck, slug: "neck" },
  { name: "Hip", image: HipImage, slug: "hip" },
  { name: "Elbow", image: Elbow, slug: "elbow" },
];

export const ratingOptions = [
  { label: "1 * Minimum", value: "1" },
  { label: "2 * Minimum", value: "2" },
  { label: "3 * Minimum", value: "3" },
  { label: "4 * Minimum", value: "4" },
  { label: "5 * Minimum", value: "5" },
];
